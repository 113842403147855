import { ICar, ILocation } from 'types/locations';
import { IProfile } from './profile';
import { IOwner } from './owner';

export interface IExtra {
  id: number;
  title: string;
  description: string;
  amount: number;
  is_recurring: boolean;
  recurring: boolean;
}

export interface IInsurance {
  id: number;
  tag: string;
  category: string;
  description: string;
  minidescription: string;
  percentage: number;
  meta: any;
}

export interface IDiscountData {
  opened: boolean;
  amount: number;
  error: string | null;
  code: string;
  isChecking: boolean;
}

export interface IPayment {
  id: number | string;
  valid: boolean;
}

export interface IPurchase {
  selectedInsurance: number | null;
  selectedOptions: number[] | [];
  discountData: IDiscountData;
}

export enum IncidentalCategory {
  PARKING_TICKET = 'parking_ticket',
  SPEEDING_TICKET = 'speeding_ticket',
  SMOKING = 'smoking',
  GLASS = 'glass',
  FLAT_TIRE = 'flat_tire',
  SCRATCHED_RIMS = 'scratched_rims',
  ACCIDENT_BODY_DAMAGE = 'accident_body_damage',
  INTERIOR_DAMAGE = 'interior_damage',
}

export interface IIncidental {
  id: number;
  category: IncidentalCategory;
  status: string;
  amount: number;
  offense_date: string;
  is_paid: boolean;
}

export interface IToll {
  id?: number;
  name: string;
  date: string;
  amount: string;
}

export interface IChargingRecord {
  id: number;
  record_id: string;
  date: string;
  amount: string;
  name: string;
  start_timestamp: string;
}

export interface IReservationRate {
  id: number;
  daily_rate: number;
  num_days: number;
}

export interface IImage {
  id: number;
  created_at: string;
  user_name: string;
  photo: string;
}

export interface IReservation {
  amount_paid: number;
  car: ICar;
  car_id: number;
  custom_return_location?: string;
  custom_pickup_location: string;
  customer_id: number;
  customer_name: string;
  customer_email: string;
  customer_phone: string;
  end_time: string;
  extras: IExtra[] | [];
  id: number;
  insurance: IInsurance;
  items_needed: string[];
  outstanding_balance: number;
  pickup_location: ILocation;
  public_token: string;
  return_location: ILocation;
  start_time: string;
  status: string;
  total_cost: number;
  total_discount: number;
  total_delivery_cost: number;
  weekly_discount: number;
  monthly_discount: number;
  starting_mileage: number;
  ending_mileage: number;
  charging_used: number;
  tolls_used: number;
  owner_discount: number;
  host_notes: string;
  extra_miles: number;
  num_days: number;
  owner_delivery_payout: number;
  owner_earnings: number;
  is_block: boolean;
  owner_requested_to_cancel: boolean;
  reservation_rates: IReservationRate[];
  incidentals: IIncidental[];
  tolls: IToll[];
  is_checkin_complete: boolean;
  agent_phone: string;
  checkin_pin: string;
  car_images: IImage[];
  toll_images: string[];
  charging_record_images: string[];
  charging_records: IChargingRecord[];
  management_commission_percentage: number;
  creator_type: string;
}

export interface ICheckin {
  id: number;
  reservation: IReservation;
  is_complete: boolean;
}

export interface ICheckout {
  signature: string | null;
  payment: IPayment | null;
  isSubmitted: boolean;
  stripePaymentIntentSecret: string | null;
}
