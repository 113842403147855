import { useEffect, ReactElement, useCallback, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Wrapper as GoogleMapWrapper, Status as GoogleMapStatus } from '@googlemaps/react-wrapper';
import { useFormik } from 'formik';
import { Tooltip } from 'react-tooltip';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import {
  parsePhoneNumber,
  isValidPhoneNumber,
  formatPhoneNumberIntl,
} from 'react-phone-number-input';
import { Icon } from '@mui/material';

import { Typography, Button, InputField, InputPhone } from 'components/common';
import { Section } from 'components/common/Section';
import { Input } from 'components/common/Input';
import { ownerActions, ownerSelectors } from 'redux/slices';
import { getBackgroundImageUri } from 'helpers/cars';
import { ILocation, ICleaner, APIStatus, IModel, ITrim, ICar, IOwner } from 'types';
import * as api from 'api';
import BackButton from 'components/common/BackButton';
import { LoadingSpinner } from 'components/LoadingSpinner';
import CreateBlock from 'components/Owner/Calendar/CreateBlock';
import variables from 'assets/scss/variables.scss';
import { capitalizeFirstChar, snakeToTitleCase } from 'helpers/utils';
import AddLocationForm from './AddLocationForm';
import AddCleanerForm from './AddCleanerForm';
import AddVirtualKeyBanner from '../AddVirtualKeyBanner';
import SmartcarUserDriverBanner from '../SmartcarUserDriverBanner';

declare const window: any;

interface Options {
  autopilot_options: { [key: string]: number };
  exterior_color_options: { [key: string]: number };
  interior_color_options: { [key: string]: number };
  license_plate_state_options: { [key: string]: number };
  model_options: IModel[];
}

const EditCar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { carId } = useParams();
  const location = useLocation();
  const [car, setCar] = useState<ICar>();
  const locations = useSelector(ownerSelectors.selectLocations) as ILocation[];
  const cleaners = useSelector(ownerSelectors.selectCleaners) as ICleaner[];
  const owner = useSelector(ownerSelectors.selectOwner) as IOwner;
  const [showCreateLocationModal, setShowCreateLocationModal] = useState(false);
  const [showCreateCleanerModal, setShowCreateCleanerModal] = useState(false);
  const [addDeliveryAgent, setAddDeliveryAgent] = useState(false);
  const [addCleaner, setAddCleaner] = useState(false);
  const [addOneDayMultiplier, setAddOneDayMultiplier] = useState(false);
  const [showCreateBlockModal, setShowCreateBlockModal] = useState(false);
  const [options, setOptions] = useState<Options>();
  const cleanerSelectRef = useRef(null);

  const queryParams = new URLSearchParams(location.search);
  const virtualKey = queryParams.get('virtual-key');

  const [lockCarStatus, setLockCarStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const [unlockCarStatus, setUnlockCarStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const [refreshCar, setRefreshCar] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const [deleteCar, setDeleteCar] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const [updateCar, setUpdateCar] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });

  useEffect(() => {
    if (car) {
      setAddDeliveryAgent(Boolean(car.delivery_person_phone));
      setAddCleaner(Boolean(car.cleaner?.id));
      setAddOneDayMultiplier(Boolean(car.one_day_multiplier));
    }
  }, [car]);

  useEffect(() => {
    if (!locations.length) {
      dispatch(ownerActions.getLocations.base());
    }

    if (!cleaners.length) {
      dispatch(ownerActions.getCleaners.base());
    }

    const fetchCar = async () => {
      try {
        const data = await api.getOwnerCar(carId);
        setCar(data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', error);
      }
    };

    const fetchOptions = async () => {
      try {
        const data = await api.getCarOptions();
        setOptions(data);
      } catch (error: any) {
        toast.error(error.response.data.error);
      }
    };

    fetchCar();
    fetchOptions();
  }, []);

  const mapRef = useCallback((node: any) => {
    if (!car) return;

    if (node !== null && window.google) {
      const map = new window.google.maps.Map(
        node,
        {
          center: {
            lat: parseFloat(car.car_state.lat),
            lng: parseFloat(car.car_state.lon),
          },
          zoom: 10,
        },
      );

      new window.google.maps.Marker({
        position: new window.google.maps.LatLng(car.car_state.lat, car.car_state.lon),
        map,
        icon: {
          url: '/images/car.png',
          origin: new window.google.maps.Point(0, 0),
        },
      });
    }
  }, [car]);

  const formikCarForm = useFormik({
    initialValues: {
      daily_rate: car?.manual_daily_rate,
      use_automated_pricing: car?.use_automated_pricing,
      minimum_daily_rate: car?.minimum_daily_rate,
      maximum_daily_rate: car?.maximum_daily_rate,
      one_day_multiplier: car?.one_day_multiplier ? car.one_day_multiplier * 100 : null,
      allow_car_substitution: car?.allow_car_substitution,
      default_location_id: car?.default_location?.id,
      hours_between_trips: car?.hours_between_trips,
      min_hours_in_advance: car?.min_hours_in_advance,
      min_days_to_book: car?.min_days_to_book,
      cleaner_id: car?.cleaner?.id,
      supports_delivery: car?.supports_delivery,
      max_delivery_distance: car?.max_delivery_distance,
      delivery_person_phone: car?.delivery_person_phone,
      license_plate: car?.license_plate,
      license_plate_state: car?.license_plate_state,
      autopilot_type: car?.autopilot_type,
      pin_to_drive: car?.pin_to_drive,
      glovebox_pin: car?.glovebox_pin,
      model_id: car?.model?.id,
      trim_id: car?.trim?.id,
      color: car?.color,
      interior_color: car?.interior_color,
      is_awaiting_approval: car?.is_awaiting_approval,
      num_seats: car?.num_seats || 5,
      // is_insured: car?.is_insured,
      // management_commission_percentage: car?.management_commission_percentage,
    },
    validateOnMount: true,
    validateOnChange: true,
    validateOnBlur: true,
    validate: vals => {
      const error = {} as any;

      if (!vals.daily_rate && !vals.use_automated_pricing) {
        error.daily_rate = 'Required';
      }

      if (vals.use_automated_pricing && !vals.minimum_daily_rate) {
        error.minimum_daily_rate = 'Required';
      }

      if (vals.use_automated_pricing && vals.minimum_daily_rate) {
        const selectedModel = options?.model_options.find(model => model.id === vals.model_id);

        if (selectedModel?.name === 'model_3' && vals.minimum_daily_rate < 35) {
          error.minimum_daily_rate = 'Minimum rate for Model 3 is $35';
        }

        if (selectedModel?.name === 'model_y' && vals.minimum_daily_rate < 40) {
          error.minimum_daily_rate = 'Minimum rate for Model Y is $40';
        }

        if (selectedModel?.name === 'model_s' && vals.minimum_daily_rate < 60) {
          error.minimum_daily_rate = 'Minimum rate for Model S is $60';
        }

        if (selectedModel?.name === 'model_x' && vals.minimum_daily_rate < 70) {
          error.minimum_daily_rate = 'Minimum rate for Model X is $70';
        }

        if (selectedModel?.name === 'cybertruck' && vals.minimum_daily_rate < 120) {
          error.minimum_daily_rate = 'Minimum rate for Cybertruck is $120';
        }
      }

      if (vals.use_automated_pricing && !vals.maximum_daily_rate) {
        error.maximum_daily_rate = 'Required';
      }

      if (addOneDayMultiplier && !vals.one_day_multiplier) {
        error.one_day_multiplier = 'Required';
      }

      if (
        addOneDayMultiplier
        && vals.one_day_multiplier
        && (vals.one_day_multiplier < 0 || vals.one_day_multiplier > 100)
      ) {
        error.one_day_multiplier = 'Must be between 0 and 100';
      }

      if (vals.default_location_id === null || vals.default_location_id === undefined) {
        error.default_location_id = 'Required';
      }

      if (!vals.hours_between_trips) {
        error.hours_between_trips = 'Required';
      }

      if (!vals.min_hours_in_advance) {
        error.min_hours_in_advance = 'Required';
      }

      if (vals.min_days_to_book && (vals.min_days_to_book < 1 || vals.min_days_to_book > 730)) {
        error.min_days_to_book = 'Must be between 1 and 730 days';
      }

      if (!vals.min_days_to_book) {
        error.min_days_to_book = 'Required';
      }

      if (addCleaner && (vals.cleaner_id === null || vals.cleaner_id === undefined)) {
        error.cleaner_id = 'Required';
      }

      if (vals.supports_delivery && !vals.max_delivery_distance) {
        error.max_delivery_distance = 'Required';
      }

      if (addDeliveryAgent && (!vals.delivery_person_phone || vals.delivery_person_phone.length < 2)) {
        error.delivery_person_phone = 'Required';
      }

      if (addDeliveryAgent && vals.delivery_person_phone && !isValidPhoneNumber(vals.delivery_person_phone)) {
        error.delivery_person_phone = 'Invalid phone number';
      }

      if (!vals.license_plate) {
        error.license_plate = 'Required';
      }

      if (vals.license_plate_state === null || vals.license_plate_state === undefined) {
        error.license_plate_state = 'Required';
      }

      if (vals.autopilot_type === null || vals.autopilot_type === undefined) {
        error.autopilot_type = 'Required';
      }

      if (!vals.pin_to_drive && !car?.is_resla_car) {
        error.pin_to_drive = 'Required';
      }

      if (vals.pin_to_drive && (vals.pin_to_drive.length !== 4 || !/^\d+$/.test(vals.pin_to_drive))) {
        error.pin_to_drive = 'Pin must be 4 digits';
      }

      if (vals.glovebox_pin && (vals.glovebox_pin.length !== 4 || !/^\d+$/.test(vals.glovebox_pin))) {
        error.glovebox_pin = 'Glovebox pin must be 4 digits';
      }

      if (vals.model_id === null || vals.model_id === undefined) {
        error.model_id = 'Required';
      }

      if (vals.trim_id === null || vals.trim_id === undefined) {
        error.trim_id = 'Required';
      }

      if (vals.color === null || vals.color === undefined) {
        error.color = 'Required';
      }

      if (vals.interior_color === null || vals.interior_color === undefined) {
        error.interior_color = 'Required';
      }

      if (vals.num_seats === null || vals.num_seats === undefined || vals.num_seats < 1) {
        error.num_seats = 'Invalid number of seats';
      }

      // if (!vals.is_insured) {
      //   error.is_insured = 'Required';
      // }

      // if (!vals.management_commission_percentage) {
      //   error.management_commission_percentage = 'Required';
      // }

      return error;
    },
    onSubmit: async vals => {
      let payload = {
        ...vals,
        license_plate: vals.license_plate?.trim().toUpperCase(),
      };

      if (vals.delivery_person_phone) {
        const phone = parsePhoneNumber(vals.delivery_person_phone) as any;
        formikCarForm.setFieldValue('delivery_person_phone', phone.number);

        payload = {
          ...payload,
          delivery_person_phone: phone.number,
        };
      }

      if (vals.one_day_multiplier) {
        payload = { ...payload, one_day_multiplier: vals.one_day_multiplier / 100 };
      }

      setUpdateCar({ loading: true, success: false, error: null });

      try {
        const data = await api.updateOwnerCar({ payload, carId: car!.id });
        setUpdateCar({ loading: false, success: true, error: null });
        dispatch(ownerActions.updateCar.success(data));

        setCar({
          ...car,
          ...data,
        });

        if (!car?.is_awaiting_approval && data.is_awaiting_approval) {
          toast.success('Your car has been submitted for review.');

          setTimeout(() => {
            navigate('/owner/cars');
          }, 2000);
        } else {
          toast.success('Car updated successfully');
        }
      } catch (error: any) {
        setUpdateCar({
          loading: false,
          success: false,
          error: { code: error.response.status, message: error.response.data.error },
        });
        toast.error(error.response.data?.error || 'Error updating your car');
      }
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!car) return;
    if (!options) return;

    if (car.license_plate_state) {
      formikCarForm.setFieldValue(
        'license_plate_state',
        options.license_plate_state_options[car.license_plate_state],
        false,
      );
    }

    if (car.autopilot_type) {
      formikCarForm.setFieldValue(
        'autopilot_type',
        options.autopilot_options[car.autopilot_type.toLowerCase()],
        false,
      );
    }

    if (car.color) {
      formikCarForm.setFieldValue(
        'color',
        options.exterior_color_options[car.color],
        false,
      );
    }

    if (car.interior_color) {
      formikCarForm.setFieldValue(
        'interior_color',
        options.interior_color_options[car.color],
        false,
      );
    }
  }, [options, car]);

  const takeHomeEarnings = useMemo(() => {
    if (!car) return 0;

    const dailyRate = formikCarForm.values.daily_rate || 0;
    const earnings = dailyRate - (dailyRate * car.management_commission_percentage);

    return parseFloat(earnings.toFixed(2));
  }, [car, formikCarForm.values.daily_rate]);

  if (!car) return null;

  const handleLock = async () => {
    setLockCarStatus({ loading: true, success: false, error: null });

    try {
      await api.lockOwnerCar(car.id);
      setLockCarStatus({ loading: false, success: true, error: null });
      toast.success('Car locked successfully');
    } catch (error: any) {
      setLockCarStatus({
        loading: false,
        success: false,
        error: { code: error.response.status, message: error.response.data.error },
      });
      toast.error(error.response.data.error);
    }
  };

  const handleUnlock = async () => {
    setUnlockCarStatus({ loading: true, success: false, error: null });

    try {
      await api.unlockOwnerCar(car.id);
      setUnlockCarStatus({ loading: false, success: true, error: null });
      toast.success('Car unlocked successfully');
    } catch (error: any) {
      setUnlockCarStatus({
        loading: false,
        success: false,
        error: { code: error.response.status, message: error.response.data.error },
      });
      toast.error(error.response.data.error);
    }
  };

  const handleRefresh = async () => {
    setRefreshCar({ loading: true, success: false, error: null });

    try {
      const data = await api.refreshOwnerCarState(car.id);
      setRefreshCar({ loading: false, success: true, error: null });
      dispatch(ownerActions.refreshCarState.success({ carState: data, carId: car.id }));
      toast.success('Car location refreshed successfully');
    } catch (error: any) {
      setRefreshCar({
        loading: false,
        success: false,
        error: { code: error.response.status, message: error.response.data.error },
      });
      toast.error(error.response.data.error);
    }
  };

  const handleSoftDeleteChange = async () => {
    const confirmText = car.soft_delete
      ? 'Are you sure you want to relist this car?'
      : 'Are you sure you want to unlist this car?';

    const successText = car.soft_delete
      ? 'Your relist request has been sent to our team for review.'
      : 'Your car has been unlisted.';

    // eslint-disable-next-line no-alert
    if (confirm(confirmText)) {
      setDeleteCar({ loading: true, success: false, error: null });

      try {
        await api.updateOwnerCarSoftDelete(car.id);
        setDeleteCar({ loading: false, success: true, error: null });
        toast.success(successText);

        setTimeout(() => {
          navigate('/owner/cars');
        }, 2000);
      } catch (error: any) {
        setDeleteCar({
          loading: false,
          success: false,
          error: { code: error.response.status, message: error.response.data.error },
        });
        toast.error(error.response.data.error);
      }
    }
  };

  const handlePhoneChange = (phoneNumber: string) => {
    if (phoneNumber && isValidPhoneNumber(phoneNumber)) {
      formikCarForm.setFieldValue('delivery_person_phone', formatPhoneNumberIntl(phoneNumber));
    } else {
      formikCarForm.setFieldValue('delivery_person_phone', phoneNumber);
    }
  };

  const backgroundImageURI = getBackgroundImageUri(car);

  const renderBody = () => {
    if (!car.soft_delete) {
      return (
        <div>
          <div className="d-flex flex-column align-items-center gap-2" style={{ marginBottom: '12px', width: '100%' }}>
            <SmartcarUserDriverBanner car={car} style={{ width: '350px' }} />

            <AddVirtualKeyBanner
              car={car}
              onSuccess={() => setCar({ ...car, smartcar_virtual_key_connected: true })}
              style={{ width: '350px' }}
              visible={Boolean(virtualKey)}
            />
          </div>

          <div className="d-flex mt-2">
            <div
              className="d-flex flex-row w-100 align-items-center justify-content-space-between"
              style={{
                height: '90px',
              }}
            >
              <div className="d-flex gap-3">
                <div
                  data-tooltip-id={
                    (!car.smartcar_virtual_key_connected && 'car-smartcar-virtual-key-disconnected') || ''
                  }
                  data-tooltip-html={`
                    Add a digital key to Smartcar<br/> to lock and unlock your car remotely`}
                >
                  <Button
                    variant="default"
                    style={{
                      backgroundColor: variables.gray5,
                      width: '90px',
                      boxShadow: 'none',
                      padding: '10px',
                    }}
                    onClick={handleLock}
                    disabled={owner?.is_fully_managed || !car.smartcar_virtual_key_connected}
                  >
                    <Icon>
                      lock
                    </Icon>

                    <Typography variant="body2" className="text-center">
                      Lock
                    </Typography>
                  </Button>
                </div>

                <div
                  data-tooltip-id={
                    (!car.smartcar_virtual_key_connected && 'car-smartcar-virtual-key-disconnected') || ''
                  }
                  data-tooltip-html={`
                    Add a digital key to Smartcar<br/> to lock and unlock your car remotely`}
                >
                  <Button
                    variant="default"
                    style={{
                      backgroundColor: variables.gray5,
                      width: '90px',
                      boxShadow: 'none',
                      padding: '10px',
                    }}
                    onClick={handleUnlock}
                    disabled={owner?.is_fully_managed || !car.smartcar_virtual_key_connected}
                  >
                    <Icon>
                      lock_open
                    </Icon>

                    <Typography variant="body2" className="text-center">
                      Unlock
                    </Typography>
                  </Button>
                </div>

                <div>
                  <Button
                    variant="default"
                    style={{
                      backgroundColor: variables.gray5,
                      width: '90px',
                      boxShadow: 'none',
                      padding: '10px',
                    }}
                    onClick={() => setShowCreateBlockModal(true)}
                    disabled={owner?.is_fully_managed}
                  >
                    <Icon>
                      event_busy
                    </Icon>

                    <Typography variant="body2" className="text-center">
                      Block off
                    </Typography>
                  </Button>
                </div>
              </div>

              <div
                data-tooltip-id={
                  (!car.smartcar_virtual_key_connected && 'car-smartcar-virtual-key-disconnected') || ''
                }
                data-tooltip-html={`
                    Add a digital key to Smartcar<br/> to refresh your car`}
              >
                <Button
                  variant="default"
                  className=""
                  type="submit"
                  style={{
                    backgroundColor: variables.gray5,
                    width: '95px',
                    boxShadow: 'none',
                    padding: '10px',
                  }}
                  onClick={handleRefresh}
                  disabled={!car.smartcar_virtual_key_connected}
                >
                  <Icon>
                    restart_alt
                  </Icon>

                  <Typography variant="body2" className="text-center">
                    Refresh
                  </Typography>
                </Button>
              </div>

              <Tooltip id="car-smartcar-virtual-key-disconnected" />
            </div>
          </div>

          <div className="mt-3">
            <GoogleMapWrapper
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ''}
              render={(status: GoogleMapStatus): ReactElement => {
                if (status === GoogleMapStatus.SUCCESS) {
                  return (
                    <div
                      ref={mapRef}
                      style={{ height: '300px', width: '100%', borderRadius: '10px' }}
                    />
                  );
                }

                return <div />;
              }}
              libraries={['geometry', 'places']}
              language="en"
              region=".us"
              version="beta"
            />
          </div>

          <div className="mt-3">
            <Section title="Pricing" isOpen>
              <div className="mt-2 d-flex flex-column gap-3" style={{ padding: '15px' }}>
                <div className="">
                  <div className="d-flex flex-row align-items-center gap-2">
                    <Icon>
                      attach_money
                    </Icon>

                    <InputField
                      label="Daily Rate"
                      type="number"
                      name="daily_rate"
                      value={formikCarForm.values.daily_rate || ''}
                      onChange={formikCarForm.handleChange}
                      error={formikCarForm.errors.daily_rate}
                      required
                      disabled={owner?.is_fully_managed || formikCarForm.values.use_automated_pricing}
                    />

                    <Icon
                      data-tooltip-id="daily-rate-tooltip-multiline"
                      data-tooltip-html={`
                    This will be the daily rate your car earns<br/> whenever on a trip -<br/>
                    Weekly and monthly trips are automatically<br/> discounted by 15% and 30% respectively`}
                    >
                      info
                    </Icon>

                    <Tooltip id="daily-rate-tooltip-multiline" />
                  </div>

                  {
                    !formikCarForm.values.use_automated_pricing && (
                      <div
                        className="mt-2"
                        style={{ marginLeft: '40px' }}
                      >
                        <Typography
                          style={{
                            color: variables.gray3,
                          }}
                        >
                          {`You'll earn $${takeHomeEarnings} per day`}
                        </Typography>
                      </div>
                    )
                  }
                </div>

                <div className="d-flex flex-row gap-2">
                  <Icon style={{ marginTop: '14px' }}>
                    currency_exchange
                  </Icon>

                  <div className="d-flex flex-column gap-3 w-100">
                    <div className="d-flex flex-row align-items-center gap-2">
                      <Input
                        value={formikCarForm.values.use_automated_pricing?.toString()}
                        type="checkbox"
                        name="use_automated_pricing"
                        placeholder="Automated Pricing"
                        onChange={formikCarForm.handleChange}
                        disabled={owner?.is_fully_managed}
                      />

                      <Icon
                        data-tooltip-id="automated-pricing-tooltip-multiline"
                        data-tooltip-html={`
                          Beta Feature - this will automatically adjust<br/>
                          your rates based on demand to<br/> maximize your bookings and earnings
                        `}
                      >
                        info
                      </Icon>

                      <Tooltip id="automated-pricing-tooltip-multiline" />
                    </div>

                    {
                      formikCarForm.values.use_automated_pricing && (
                        <div className="d-flex flex-column gap-3">
                          <div className="d-flex flex-row gap-3">
                            <InputField
                              label="Min Rate"
                              type="number"
                              name="minimum_daily_rate"
                              value={formikCarForm.values.minimum_daily_rate?.toString() || ''}
                              onChange={formikCarForm.handleChange}
                              error={formikCarForm.errors.minimum_daily_rate}
                              required
                              disabled={owner?.is_fully_managed}
                              placeholder="20"
                            />

                            <InputField
                              label="Max Rate"
                              type="number"
                              name="maximum_daily_rate"
                              value={formikCarForm.values.maximum_daily_rate?.toString() || ''}
                              onChange={formikCarForm.handleChange}
                              error={formikCarForm.errors.maximum_daily_rate}
                              required
                              disabled={owner?.is_fully_managed}
                              placeholder="200"
                            />
                          </div>

                          {
                            (!car.minimum_daily_rate || !car.maximum_daily_rate) && (
                              <div>
                                <Typography>
                                  We recommend setting the minimum and maximum to a number
                                  you're comfortable with
                                </Typography>
                              </div>
                            )
                          }
                        </div>
                      )
                    }

                    <div>
                      <Typography variant="body2">
                        * By default guests see lower priced cars first when choosing a car.
                        Keep in mind that pricing the car too high will mean your car ranks
                        lower than more affordable options.
                      </Typography>
                    </div>

                    <div className="d-flex flex-row align-items-center gap-2">
                      <Input
                        value={addOneDayMultiplier.toString()}
                        type="checkbox"
                        name="add_one_day_multiplier"
                        placeholder="Add one day trip upcharge?"
                        onChange={(e) => {
                          setAddOneDayMultiplier(e.target.checked);

                          if (!e.target.checked) {
                            formikCarForm.setFieldValue('one_day_multiplier', null);
                          }
                        }}
                        disabled={owner?.is_fully_managed}
                      />

                      <Icon
                        data-tooltip-id="one-day-multiplier-tooltip-multiline"
                        data-tooltip-html={`
                          This will increase the price of 1 day trips only.<br/>
                          Be advised - this will likely decrease<br/> your number of bookings.
                        `}
                      >
                        info
                      </Icon>

                      <Tooltip id="one-day-multiplier-tooltip-multiline" />
                    </div>

                    {
                      addOneDayMultiplier && (
                        <div style={{ width: '250px' }}>
                          <InputField
                            label="One day trip upcharge %"
                            name="one_day_multiplier"
                            value={formikCarForm.values.one_day_multiplier || ''}
                            onChange={formikCarForm.handleChange}
                            error={formikCarForm.errors.one_day_multiplier}
                            required
                            disabled={owner?.is_fully_managed}
                            min={0}
                            max={100}
                          />
                        </div>
                      )
                    }

                    <div className="d-flex align-items-center gap-2">
                      <Input
                        value={formikCarForm.values.allow_car_substitution?.toString()}
                        type="checkbox"
                        name="allow_car_substitution"
                        placeholder="Allow Substitutions?"
                        onChange={formikCarForm.handleChange}
                        disabled={owner?.is_fully_managed}
                      />

                      <Icon
                        data-tooltip-id="allow-car-substitutions-tooltip-multiline"
                        data-tooltip-html={`
                          Allow us to move last minute guests into your car<br/>
                          from another rental - this increases bookings<br/> significantly,
                          but you may get guests<br/> that are upgraded for free from<br/>
                          another rental, so in some cases trips will have<br/> a slightly lower daily rate.
                        `}
                      >
                        info
                      </Icon>

                      <Tooltip id="allow-car-substitutions-tooltip-multiline" />
                    </div>
                  </div>
                </div>
              </div>
            </Section>
          </div>

          <div className="mt-3">
            <Section title="Preferences" isOpen>
              <div
                className="d-flex flex-row align-items-center justify-content-space-between mt-3"
              >
                <Typography variant="h3">
                  Pickup Location
                </Typography>

                <div className="w-100">
                  <Select
                    onChange={
                      (option: any) => formikCarForm.setFieldValue('default_location_id', parseInt(option.value, 10))
                    }
                    options={
                      locations.map(loc => ({
                        value: loc.id.toString(),
                        label: loc.address,
                      }))
                    }
                    defaultValue={{ value: car.default_location?.address, label: car.default_location?.address }}
                    placeholder="Select a Location"
                    isSearchable
                    isDisabled={owner?.is_fully_managed}
                  />
                  <div
                    style={{ color: 'blue', marginLeft: '5px' }}
                    onClick={() => !owner.is_fully_managed && setShowCreateLocationModal(true)}
                  >
                    Add new location
                  </div>

                  {
                    formikCarForm.errors.default_location_id && (
                      <div style={{ color: 'red' }}>
                        {formikCarForm.errors.default_location_id}
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="d-flex flex-row align-items-center justify-content-space-between mt-3">
                <Typography variant="h3">
                  Time Between Trips
                </Typography>

                <div className="w-100">
                  <Select
                    onChange={(option: any) => formikCarForm.setFieldValue('hours_between_trips', option.value)}
                    options={[
                      { value: 3, label: '3 hours' },
                      { value: 6, label: '6 hours' },
                      { value: 12, label: '12 hours' },
                      { value: 24, label: '24 hours' },
                    ]}
                    defaultValue={{
                      value: car.hours_between_trips,
                      label: `${car.hours_between_trips} hours`,
                    }}
                    isDisabled={owner?.is_fully_managed}
                  />

                  {
                    formikCarForm.errors.hours_between_trips && (
                      <div style={{ color: 'red' }}>
                        {formikCarForm.errors.hours_between_trips}
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="d-flex flex-row align-items-center justify-content-space-between mt-3">
                <Typography variant="h3">
                  Min Time in Advance
                </Typography>

                <div className="w-100">
                  <Select
                    onChange={(option: any) => formikCarForm.setFieldValue('min_hours_in_advance', option.value)}
                    options={[
                      { value: 3, label: '3 hours' },
                      { value: 6, label: '6 hours' },
                      { value: 12, label: '12 hours' },
                      { value: 24, label: '24 hours' },
                    ]}
                    defaultValue={{
                      value: car.min_hours_in_advance,
                      label: `${car.min_hours_in_advance} hours`,
                    }}
                    isDisabled={owner?.is_fully_managed}
                  />

                  {
                    formikCarForm.errors.min_hours_in_advance && (
                      <div style={{ color: 'red' }}>
                        {formikCarForm.errors.min_hours_in_advance}
                      </div>
                    )
                  }
                </div>
              </div>

              <div className="d-flex flex-row align-items-center justify-content-space-between mt-3">
                <Typography variant="h3">
                  Min Days to Book
                </Typography>

                <div className="d-flex flex-column gap-3 w-100">
                  <div
                    className="d-flex flex-row gap-2 align-items-center"
                    style={{ width: '100%', maxWidth: '300px' }}
                  >
                    <InputField
                      label="Min Days to Book"
                      type="number"
                      name="min_days_to_book"
                      value={formikCarForm.values.min_days_to_book || ''}
                      onChange={formikCarForm.handleChange}
                      error={formikCarForm.errors.min_days_to_book}
                      required
                      disabled={owner?.is_fully_managed}
                      min={1}
                      max={730} // 2 years
                    />

                    <Icon
                      data-tooltip-id="min-days-to-book-tooltip-multiline"
                      data-tooltip-html={`
                      Your car will only show up and be available to book<br/>
                      if the guest is searching for a trip of this number<br/> of days or longer.
                      We recommend leaving this at 1.
                    `}
                    >
                      info
                    </Icon>

                    <Tooltip id="min-days-to-book-tooltip-multiline" />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-row align-items-center mt-3 gap-3">
                <Typography variant="h3">
                  Cleaner
                </Typography>

                <div className="d-flex flex-column gap-3 w-100">
                  <div
                    className="d-flex flex-row gap-2 align-items-center"
                    style={{ width: '100%', maxWidth: '300px' }}
                  >
                    <Input
                      value={addCleaner.toString()}
                      type="checkbox"
                      name="add_cleaner"
                      placeholder="Add Cleaner?"
                      onChange={(e) => {
                        setAddCleaner(e.target.checked);

                        if (!e.target.checked) {
                          formikCarForm.setFieldValue('cleaner_id', null);
                        }
                      }}
                      disabled={owner?.is_fully_managed}
                    />

                    <Icon
                      data-tooltip-id="supports-delivery-tooltip-multiline"
                      data-tooltip-html={`
                        Add a new user to receive booking<br/>
                        and car return alerts<br/>
                        and complete the cleaning checklist after each trip
                      `}
                    >
                      info
                    </Icon>

                    <Tooltip id="supports-delivery-tooltip-multiline" />
                  </div>
                </div>
              </div>

              {
                addCleaner && (
                  <div className="mt-3">
                    <Select
                      ref={cleanerSelectRef}
                      onChange={(option: any) => {
                        if (!option) {
                          formikCarForm.setFieldValue('cleaner_id', null);
                          return;
                        }

                        formikCarForm.setFieldValue('cleaner_id', parseInt(option.value, 10));
                      }}
                      options={
                        cleaners.map(cleaner => ({
                          value: cleaner.id.toString(),
                          label: `${cleaner.firstname} ${cleaner.lastname}`,
                        }))
                      }
                      defaultValue={car.cleaner && {
                        value: `${car.cleaner.firstname} ${car.cleaner.lastname}`,
                        label: `${car.cleaner.firstname} ${car.cleaner.lastname}`
                      }}
                      placeholder="Select a cleaner"
                      isSearchable
                      isDisabled={owner?.is_fully_managed}
                      isClearable
                    />
                    <div
                      style={{ color: 'blue', marginLeft: '5px' }}
                      onClick={() => !owner.is_fully_managed && setShowCreateCleanerModal(true)}
                    >
                      Add new cleaner
                    </div>

                    {
                      formikCarForm.errors.cleaner_id && (
                        <div style={{ color: 'red' }}>
                          {formikCarForm.errors.cleaner_id}
                        </div>
                      )
                    }
                  </div>
                )
              }

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row gap-2 mt-3">
                  <Icon style={{ marginTop: '15px' }}>
                    sports_score
                  </Icon>

                  <div className="d-flex flex-column gap-3 w-100">
                    <div
                      className="d-flex flex-row gap-2 align-items-center justify-content-space-around"
                      style={{ width: '100%', maxWidth: '300px' }}
                    >
                      <Input
                        value={formikCarForm.values.supports_delivery?.toString()}
                        type="checkbox"
                        name="supports_delivery"
                        placeholder="Accepts Delivery?"
                        onChange={formikCarForm.handleChange}
                        disabled={owner?.is_fully_managed}
                      />

                      <Icon
                        data-tooltip-id="supports-delivery-tooltip-multiline"
                        data-tooltip-html={`
                          Deliver the car for extra earnings starting at<br/>
                          $127.50 additional earnings per trip<br/> and increasing based on the distance
                        `}
                      >
                        info
                      </Icon>

                      <Tooltip id="supports-delivery-tooltip-multiline" />
                    </div>

                    {
                      formikCarForm.values.supports_delivery && (
                        <div
                          className="d-flex flex-row gap-2 align-items-center"
                          style={{ width: '200px' }}
                        >
                          <InputField
                            label="Max Distance (miles)"
                            type="number"
                            name="max_delivery_distance"
                            value={formikCarForm.values.max_delivery_distance}
                            onChange={formikCarForm.handleChange}
                            error={
                              formikCarForm.errors.max_delivery_distance
                            }
                            required
                            disabled={owner?.is_fully_managed}
                          />
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>

              <div className="d-flex flex-column gap-2">
                <div className="d-flex flex-row gap-2 mt-3">
                  <Icon style={{ marginTop: '15px' }}>
                    person
                  </Icon>

                  <div className="d-flex flex-column gap-3 w-100">
                    <div
                      className="d-flex flex-row gap-2 align-items-center justify-content-space-around"
                      style={{ width: '100%', maxWidth: '300px' }}
                    >
                      <Input
                        value={addDeliveryAgent?.toString()}
                        type="checkbox"
                        name="add_delivery_agent"
                        placeholder="Add Delivery Agent?"
                        onChange={(e) => {
                          setAddDeliveryAgent(e.target.checked);
                          formikCarForm.setFieldValue('delivery_person_phone', '');
                        }}
                        disabled={owner?.is_fully_managed}
                      />

                      <Icon
                        data-tooltip-id="add-delivery-agent-tooltip-multiline"
                        data-tooltip-html={`
                          Agents assigned to this car will also get<br/> delivery instructions
                          - they’ll get the car info,<br/> pickup address, delivery address,<br/>
                          guest details and several reminders to deliver the car.
                        `}
                      >
                        info
                      </Icon>

                      <Tooltip id="add-delivery-agent-tooltip-multiline" />
                    </div>

                    {
                      addDeliveryAgent && (
                        <div
                          className="d-flex flex-row gap-2"
                          style={{ width: '250px' }}
                        >
                          <InputPhone
                            value={formikCarForm.values.delivery_person_phone!}
                            onChange={(val: any) => handlePhoneChange(val!)}
                            style={{ width: '100%' }}
                            error={
                              formikCarForm.errors.delivery_person_phone
                            }
                          />
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            </Section>
          </div>

          <div className="mt-3">
            <Section title="Attributes" isOpen>
              <div className="mt-2 d-flex flex-column gap-3" style={{ padding: '15px' }}>
                <div className="d-flex flex-row align-items-center gap-2 w-100">
                  <Icon>
                    directions_car
                  </Icon>

                  <InputField
                    label="Vin"
                    type="text"
                    name="vin"
                    value={car?.vin || ''}
                    onChange={formikCarForm.handleChange}
                    required
                    disabled
                  />
                </div>

                <div className="d-flex flex-row gap-2 w-100">
                  <Icon style={{ marginTop: '15px' }}>
                    badge
                  </Icon>

                  <div className="d-flex flex-column gap-3">
                    <InputField
                      label="License Plate"
                      type="text"
                      name="license_plate"
                      value={formikCarForm.values.license_plate || ''}
                      onChange={(event: any) => {
                        const { value } = event.target;
                        formikCarForm.setFieldValue('license_plate', value.toUpperCase());
                      }}
                      required
                      error={formikCarForm.errors.license_plate}
                      style={{ textTransform: 'uppercase' }}
                    />

                    <div
                      style={{ width: '200px' }}
                    >
                      <div>
                        License Plate State
                      </div>

                      <div className="w-100">
                        <Select
                          onChange={(option: any) => formikCarForm.setFieldValue('license_plate_state', option.value)}
                          options={
                            options?.license_plate_state_options
                            && Object.entries(options.license_plate_state_options)
                              .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                              .map(([key, value]) => ({
                                value,
                                label: capitalizeFirstChar(snakeToTitleCase(key)),
                              }))
                          }
                          defaultValue={{
                            value: options?.license_plate_state_options[car.license_plate_state],
                            label: capitalizeFirstChar(snakeToTitleCase(car.license_plate_state)),
                          }}
                          placeholder="Select a license plate state"
                          isSearchable
                        />

                        {
                          formikCarForm.errors.license_plate_state && (
                            <div style={{ color: 'red' }}>
                              {formikCarForm.errors.license_plate_state}
                            </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <Typography style={{ marginLeft: '30px' }}>
                    Autopilot
                  </Typography>

                  <div className="d-flex flex-row align-items-center gap-2 w-100">
                    <Icon>
                      smart_toy
                    </Icon>

                    <div className="w-100">
                      <Select
                        onChange={(option: any) => formikCarForm.setFieldValue('autopilot_type', option.value)}
                        options={
                          options?.autopilot_options && Object.entries(options.autopilot_options)
                            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                            .map(([key, value]) => ({
                              value,
                              label: capitalizeFirstChar(key),
                            }))
                        }
                        defaultValue={{
                          value: options?.autopilot_options[car.autopilot_type?.toLowerCase()],
                          label: car.autopilot_type,
                        }}
                        placeholder="Select an autopilot type"
                      />

                      {
                        formikCarForm.errors.autopilot_type && (
                          <div style={{ color: 'red' }}>
                            {formikCarForm.errors.autopilot_type}
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>

                <div className="d-flex flex-row align-items-center gap-2 w-100 mt-3">
                  <Icon>
                    lock
                  </Icon>

                  <InputField
                    label="PIN to Drive"
                    type="input"
                    name="pin_to_drive"
                    value={formikCarForm.values.pin_to_drive || ''}
                    onChange={formikCarForm.handleChange}
                    required
                    error={formikCarForm.errors.pin_to_drive}
                    maxLength="4"
                  />

                  <Icon
                    data-tooltip-id="pin-to-drive-tooltip-multiline"
                    data-tooltip-html={`
                      Required - the pin to drive is a 4 digit code<br/>
                      you can set on your Tesla to lock access even<br/>
                      with the keycard - to setup, go to safety and<br/>
                      security in your Tesla’s settings page, scroll down<br/>
                      and enable pin to drive. Do not give your guests<br/>
                      this pin, our system will provide it after<br/>
                      they’ve completed the checkin
                    `}
                  >
                    info
                  </Icon>

                  <Tooltip id="pin-to-drive-tooltip-multiline" />
                </div>

                <div className="d-flex flex-row align-items-center gap-2 w-100 mt-3">
                  <Icon>
                    inventory_2
                  </Icon>

                  <InputField
                    label="Glovebox Pin"
                    type="input"
                    name="glovebox_pin"
                    value={formikCarForm.values.glovebox_pin || ''}
                    onChange={formikCarForm.handleChange}
                    error={formikCarForm.errors.glovebox_pin}
                    maxLength="4"
                  />

                  <Icon
                    data-tooltip-id="glovebox-pin-tooltip-mulitline"
                    data-tooltip-html={`
                      Optional - For added security, keep your keycard<br/>
                      or fob in the glovebox and setup glovebox pin<br/>
                      in the safety and security tab<br/>
                      - guests will receive this pin once checkin is completed.
                    `}
                  >
                    info
                  </Icon>

                  <Tooltip id="glovebox-pin-tooltip-mulitline" />
                </div>

                <div>
                  <Typography style={{ marginLeft: '30px' }}>
                    Model
                  </Typography>

                  <div className="d-flex flex-row align-items-center gap-2 w-100 mt-3">
                    <Icon
                      data-tooltip-id="model-type-icon-tooltip"
                      data-tooltip-content="Model"
                    >
                      directions_car
                    </Icon>

                    <Tooltip id="model-type-icon-tooltip" />

                    <div className="w-100">
                      <Select
                        onChange={(option: any) => {
                          formikCarForm.setValues({
                            ...formikCarForm.values,
                            model_id: option.value,
                            trim_id: undefined,
                          });
                        }}
                        options={
                          options?.model_options
                          && options?.model_options
                            .map((model: IModel) => ({
                              value: model.id,
                              label: model.display_name,
                            }))
                        }
                        defaultValue={{
                          value: car.model?.id,
                          label: car.model?.display_name,
                        }}
                        placeholder="Select a model type"
                        isSearchable
                      />

                      {
                        formikCarForm.errors.model_id && (
                          <div style={{ color: 'red' }}>
                            {formikCarForm.errors.model_id}
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>

                {
                  options?.model_options
                  && options?.model_options.find(opt => opt.id === formikCarForm.values.model_id)?.trims && (
                    <div>
                      <Typography style={{ marginLeft: '30px' }}>
                        Trim
                      </Typography>

                      <div className="d-flex flex-row align-items-center gap-2 w-100 mt-3">
                        <Icon
                          data-tooltip-id="model-type-icon-tooltip"
                          data-tooltip-content="Model"
                        >
                          directions_car
                        </Icon>

                        <Tooltip id="model-type-icon-tooltip" />

                        <div className="w-100">

                          <Select
                            onChange={(option: any) => formikCarForm.setFieldValue('trim_id', option.value)}
                            options={
                              options?.model_options?.find(opt => opt.id === formikCarForm.values.model_id)?.trims
                                .map((trim: ITrim) => ({
                                  value: trim.id,
                                  label: trim.display_name,
                                }))
                            }
                            placeholder="Select a model type"
                            isSearchable
                            value={{
                              value: formikCarForm.values.trim_id,
                              label: options?.model_options
                                .find(opt => opt.id === formikCarForm.values.model_id)?.trims
                                .find(t => t.id === formikCarForm.values.trim_id)?.display_name,
                            }}
                          />

                          {
                            formikCarForm.errors.trim_id && (
                              <div style={{ color: 'red' }}>
                                {formikCarForm.errors.trim_id}
                              </div>
                            )
                          }
                        </div>

                        <Icon
                          data-tooltip-id="vehicle-trim-tooltip"
                          data-tooltip-content="What trim is your car?"
                        >
                          info
                        </Icon>

                        <Tooltip id="vehicle-trim-tooltip" />
                      </div>
                    </div>
                  )
                }

                <div>
                  <Typography style={{ marginLeft: '30px' }}>
                    Exterior Color
                  </Typography>

                  <div className="d-flex flex-row align-items-center gap-2 w-100 mt-3">
                    <Icon
                      data-tooltip-id="exterior-color-icon-tooltip"
                      data-tooltip-content="Exterior Color"
                    >
                      format_color_fill
                    </Icon>

                    <Tooltip id="exterior-color-icon-tooltip" />

                    <div className="w-100">

                      <Select
                        onChange={(option: any) => formikCarForm.setFieldValue('color', option.value)}
                        options={
                          options?.exterior_color_options
                          && Object.entries(options?.exterior_color_options)
                            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                            .map(([color, value]) => ({
                              value,
                              label: capitalizeFirstChar(color),
                            }))
                        }
                        defaultValue={{
                          value: options?.exterior_color_options[car.color] || '',
                          label: capitalizeFirstChar(car.color),
                        }}
                        placeholder="Select an exterior color"
                        isSearchable
                      />

                      {
                        formikCarForm.errors.color && (
                          <div style={{ color: 'red' }}>
                            {formikCarForm.errors.color}
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>

                <div>
                  <Typography style={{ marginLeft: '30px' }}>
                    Interior Color
                  </Typography>

                  <div className="d-flex flex-row align-items-center gap-2 w-100 mt-3">
                    <Icon
                      data-tooltip-id="interior-color-icon-tooltip"
                      data-tooltip-content="Interior Color"
                    >
                      format_color_fill
                    </Icon>

                    <Tooltip id="interior-color-icon-tooltip" />

                    <div className="w-100">
                      <Select
                        onChange={(option: any) => formikCarForm.setFieldValue('interior_color', option.value)}
                        options={
                          options?.interior_color_options
                          && Object.entries(options?.interior_color_options)
                            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                            .map(([color, value]) => ({
                              value,
                              label: capitalizeFirstChar(color),
                            }))
                        }
                        defaultValue={{
                          value: options?.interior_color_options[car.interior_color] || '',
                          label: capitalizeFirstChar(car.interior_color),
                        }}
                        placeholder="Select an interior color"
                        isSearchable
                      />

                      {
                        formikCarForm.errors.interior_color && (
                          <div style={{ color: 'red' }}>
                            {formikCarForm.errors.interior_color}
                          </div>
                        )
                      }
                    </div>
                  </div>

                  <div className="d-flex flex-row align-items-center gap-2 w-100 mt-3">
                    <Icon>
                      event_seat
                    </Icon>

                    <div>
                      <InputField
                        label="Num Seats"
                        type="number"
                        name="num_seats"
                        value={formikCarForm.values.num_seats}
                        onChange={formikCarForm.handleChange}
                        error={
                          formikCarForm.errors.num_seats
                        }
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Section>
          </div>

          {/* {
          owner?.is_superadmin && (
            <div className="mt-3">
              <Section title="Admin">
                <div className="mt-2 d-flex flex-column gap-3">
                  <div className="d-flex align-items-center gap-2">
                    <Input
                      value={formikCarForm.values.is_insured?.toString()}
                      type="checkbox"
                      name="is_insured"
                      placeholder="Is Insured"
                      onChange={formikCarForm.handleChange}
                    />
                  </div>

                  <InputField
                    label="Management Commission"
                    type="text"
                    name="management_commission_percentage"
                    value={formikCarForm.values.management_commission_percentage?.toString()}
                    onChange={formikCarForm.handleChange}
                    required
                  />
                </div>
              </Section>
            </div>
          )
        } */}

          <div className="d-flex flex-column gap-3 justify-content-center align-items-center mt-4">
            <div className="d-flex flex-row gap-2">
              <Button
                onClick={formikCarForm.handleSubmit}
              >
                Save Changes
              </Button>

              {
                !car?.is_approved && !car?.is_awaiting_approval && (
                  <Button
                    onClick={() => {
                      formikCarForm.setFieldValue('is_awaiting_approval', true);
                      formikCarForm.handleSubmit();
                    }}
                    style={{
                      backgroundColor: variables.black,
                      color: variables.white,
                    }}
                  >
                    Submit for Review
                  </Button>
                )
              }
            </div>

            <Typography
              variant="body2"
              style={{ color: variables.red2 }}
              onClick={handleSoftDeleteChange}
            >
              Unlist Car
            </Typography>
          </div>
        </div>
      );
    }

    return (
      <div className="d-flex flex-column gap-2 align-items-center mt-4">
        <div>
          <Typography>
            This car is unlisted and will not show on our booking portal.
            To send a relisting request to our team, click the button below.
          </Typography>
        </div>

        <Button
          style={{
            boxShadow: 'none',
            color: variables.red2,
          }}
          onClick={handleSoftDeleteChange}
        >
          Relist Car
        </Button>
      </div>
    );
  };

  return (
    <div
      className="d-flex flex-column w-100 align-items-center"
      style={{ padding: '20px' }}
    >
      <ToastContainer autoClose={3500} />

      {
        (
          lockCarStatus.loading
          || unlockCarStatus.loading
          || refreshCar.loading
          || deleteCar.loading
          || updateCar.loading
        ) && (
          <LoadingSpinner absolute />
        )
      }

      <div
        style={{ maxWidth: '560px' }}
        className="w-100"
      >
        <div className="d-flex flex-row mt-4 justify-content-space-around">
          <div>
            <BackButton />
          </div>

          <div
            className="d-flex flex-column align-items-center"
            style={{ width: '50%' }}
          >
            <Typography variant="h1">
              {car.license_plate}
            </Typography>

            <Typography variant="body2">
              {car.vin}
            </Typography>

            <img
              src={backgroundImageURI}
              alt="car"
              className="d-flex"
              style={{ maxWidth: '100%', maxHeight: '100%', height: 'auto' }}
            />
          </div>

          <div className="d-flex flex-column gap-4">
            <div className="d-flex flex-row align-items-center gap-2">
              <div
                style={{ backgroundColor: variables.green1, height: '60px', width: '5px', borderRadius: '100px' }}
              />

              <Typography variant="h1">
                {`${car.car_state.usable_battery_level}%`}
              </Typography>
            </div>

            <div className="d-flex flex-row align-items-center gap-2">
              <div
                style={{ backgroundColor: variables.gray2, height: '60px', width: '5px', borderRadius: '100px' }}
              />

              <Typography variant="h1">
                {car.car_state.odometer}
              </Typography>
            </div>
          </div>

        </div>

        {
          renderBody()
        }
      </div>

      <CreateBlock
        isOpen={showCreateBlockModal}
        car={car}
        onClose={() => setShowCreateBlockModal(false)}
      />

      <AddLocationForm
        isOpen={showCreateLocationModal}
        onClose={() => setShowCreateLocationModal(false)}
      />

      <AddCleanerForm
        isOpen={showCreateCleanerModal}
        onClose={() => setShowCreateCleanerModal(false)}
      />
    </div>
  );
};

export default EditCar;
