import { useMemo } from 'react';
import moment from 'moment';
import { Icon } from '@mui/material';

import { Card, Chip, Typography } from 'components/common';
import { CarStatusEnum, ILocation, IReservation } from 'types';

import variables from 'assets/scss/variables.scss';
import { getBackgroundImageUri } from 'helpers/cars';
import { getResColor } from 'helpers/reservations';
import { capitalizeFirstChar } from 'helpers/utils';

interface Props {
  reservation: IReservation;
  onClick?: () => void;
}

const ReservationCard = ({
  reservation,
  onClick,
}: Props) => {
  if (!reservation) return null;

  const { car } = reservation;

  if (!car) return null;

  const isPickup = useMemo(() => {
    return moment(reservation.start_time).isAfter(moment());
  }, [reservation]);

  const { location, pickupOrDelivery } = useMemo(() => {
    let location: ILocation | string;
    let pickupOrDelivery: string;

    if (isPickup && reservation.custom_pickup_location) {
      location = reservation.custom_pickup_location;
      pickupOrDelivery = 'Delivery';
    } else if (isPickup) {
      location = reservation.pickup_location;
      pickupOrDelivery = 'Pickup';
    } else if (reservation.custom_return_location) {
      location = reservation.custom_return_location;
      pickupOrDelivery = 'Return Delivery';
    } else {
      location = reservation.return_location;
      pickupOrDelivery = 'Return';
    }

    return { location, pickupOrDelivery };
  }, [isPickup, reservation]);

  const formattedTime = useMemo(() => {
    const dateStr = isPickup
      ? moment.tz(
        reservation.start_time,
        reservation.pickup_location.timezone,
      ).format('MM/DD/YY, h:mma z')
      : moment.tz(
        reservation.end_time,
        reservation.return_location.timezone,
      ).format('MM/DD/YY, h:mma z');

    return `${pickupOrDelivery} @ ${dateStr}`;
  }, [isPickup, reservation]);

  const backgroundImageURI = getBackgroundImageUri(car);
  let backgroundColor = variables.$gray2;

  if (car.status === CarStatusEnum.NotConnected) {
    backgroundColor = variables.red6;
  } else {
    backgroundColor = variables.$gray2;
  }

  return (
    <Card
      type="button"
      radius="large"
      className="w-100"
      style={{ backgroundColor }}
      onClick={onClick}
    >
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <Typography variant="h2" className="m-0">
            {car.license_plate}
          </Typography>

          <Typography variant="body2" style={{ color: getResColor(reservation) }}>
            {
              capitalizeFirstChar(reservation.status)
            }
          </Typography>
        </div>

        <div className="d-flex flex-column">
          <Typography variant="h3" className="m-0">
            {
              formattedTime
            }
          </Typography>

          {
            car.status === CarStatusEnum.NotConnected && (
              <Typography variant="h1" className="m-0">
                Car is disconnected
              </Typography>
            )
          }
        </div>
      </div>

      <div className="d-flex w-100 justify-content-center">
        <img
          src={backgroundImageURI}
          alt="car"
          style={{ objectFit: 'contain', height: '150px' }}
        />
      </div>

      <div>
        <div className="mb-2">
          <Chip
            className="flex-grow-1 gap-3"
            icon={<Icon>location_on</Icon>}
            text={typeof location === 'string' ? location : location.address}
            variant="body2"
          />
        </div>
        <div className="d-flex gap-2">
          <Chip
            className="flex-grow-1 gap-3"
            icon={<Icon>schedule</Icon>}
            text={
              moment.tz(
                reservation.start_time,
                reservation.pickup_location.timezone,
              ).format('h:mma z - MM/DD/YY')
            }
            variant="body2"
          />
          <Chip
            className="flex-grow-1 gap-3"
            icon={<Icon>browse_gallery</Icon>}
            text={
              moment.tz(
                reservation.end_time,
                reservation.return_location.timezone,
              ).format('h:mma z - MM/DD/YY')
            }
            variant="body2"
          />
        </div>
      </div>
    </Card>
  );
};

export default ReservationCard;
