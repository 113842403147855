import { request } from '../helpers/request';
import { request as ownerRequest } from '../helpers/request/owner';

const main = `${process.env.REACT_APP_API_ENDPOINT}`;
const api = `${process.env.REACT_APP_API_ENDPOINT}/api/v1`;
const apiV2 = `${process.env.REACT_APP_API_ENDPOINT}/api/v2`;
export const getAllLocations = ({ partner }: any) => request.get(`${api}/rental_locations/cities`, {
  params: {
    partner,
  }
}).then(res => res.data);

export const getAvailableCars = ({
  city,
  start,
  end,
  delivery_address,
  return_address,
  delivery,
  portal_partner,
}: any) => request.get(`${api}/cars/city/available`, {
  params: {
    city,
    start_datetime: start,
    end_datetime: end,
    delivery_address,
    return_address,
    supports_delivery: delivery,
    partner: portal_partner
  }
}).then(res => res.data);

export const checkDiscountCode = ({ code }: any) => request
  .post(`${api}/discounts/check`, { code })
  .then(res => res.data);

export const getCarExtra = () => request
  .get(`${api}/extras`)
  .then(res => res.data);

export const getInsurances = () => request
  .get(`${api}/insurances`)
  .then(res => res.data);

export const startLogin = ({ phone, partner }: any) => {
  const params = new URLSearchParams({
    useragent: 'eon-app-id-rei-',
    ...(partner && { partner })
  });

  return request
    .post(`${api}/login/otp?${params.toString()}`, { phone })
    .then(res => res.data);
};

export const verifyLogin = ({ phone, otp }: any) => request
  .post(`${api}/login/otp/verify`, { phone, otp })
  .then(res => res.data);

export const getProfile = () => request
  .get(`${api}/customers/me`)
  .then(res => res.data);

export const updateProfile = ({ firstname, lastname, email, dob, referralCode }: any) => request
  .post(`${api}/customers/me/update`, {
    firstname,
    lastname,
    email,
    dob,
    referral_code: referralCode,
  })
  .then(res => res.data);

export const getPaymentMethods = () => request
  .get(`${api}/customers/me/payment/methods`)
  .then(res => res.data);

export const deletePaymentMethods = (id: any) => request
  .delete(`${api}/customers/me/payment?id=${id}`)
  .then(res => res.data);

export const createReservation = (data: any) => request
  .post(`${api}/reservations/new`, data)
  .then(res => res.data);

export const getReservation = (id: any) => request
  .get(`${api}/reservations/${id}`)
  .then(res => res.data);

export const cancelReservation = (id: any) => request
  .post(`${api}/reservations/${id}/cancel`)
  .then(res => res.data);

export const finalizeReservation = (data: any) => request
  .post(`${api}/reservations/finalize`, data)
  .then(res => res.data);

export const updateReservationUseCredits = (id: any) => request
  .put(`${api}/reservations/${id}/use-credits`)
  .then(res => res.data);

export const updateReservationPaymentIntent = (id: any, newAmount: number) => request
  .put(`${api}/reservations/${id}/payment-intent`, { new_amount: newAmount })
  .then(res => res.data);

export const createStripeSetupIntent = () => request
  .post(`${api}/customers/me/setup-intent`)
  .then(res => res.data);

export const getContractFullUrl = ({ id, public_token }: any) => (
  `${main}/reservations/${id}/contract/full?public_token=${public_token}`
);

export const getPartnerConfig = (partner: any) => request
  .get(`${api}/organizations/${partner}`)
  .then(res => res.data);

export const getCheckinInstructions = (reservationId: number) => request
  .get(`${apiV2}/checkins/instructions?reservation_id=${reservationId}`)
  .then(res => res.data);

export const createCheckin = (reservationId: number) => request
  .post(`${apiV2}/checkins`, { reservation_id: reservationId })
  .then(res => res.data);

export const completedCarInstructions = ({ firstname, lastname }: any) => request
  .post(`${apiV2}/car_instructions/mosaic`, { firstname, lastname })
  .then(res => res.data);

export const completeCheckin = (reservationId: number) => request
  .put(`${apiV2}/checkins`, { reservation_id: reservationId })
  .then(res => res.data);

export const updateReservation = ({ payload, reservationId }: any) => {
  const formData = new FormData();

  for (let i = 0; i < payload.car_images.length; i++) {
    formData.append('car_images[]', payload.car_images[i]);
  }

  return request
    .put(`${api}/reservations/${reservationId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data);
};

export const getInsuranceStatus = (reservationId: number) => request
  .get(`${api}/customers/insurance/status?reservation_id=${reservationId}`)
  .then(res => res.data);

export const createInsuranceSession = () => request
  .post(`${api}/customers/insurance/session`)
  .then(res => res.data);

export const connectInsuranceAccount = (authCode: string) => request
  .post(`${api}/customers/insurance/connect`, { auth_code: authCode })
  .then(res => res.data);

export const validateInsuranceAccount = (reservationId: number) => request
  .post(`${api}/customers/insurance/validate?reservation_id=${reservationId}`)
  .then(res => res.data);

export const uploadCustomerVerifyPaymentPhoto = ({ payload }: any) => {
  const formData = new FormData();

  for (let i = 0; i < payload.verify_payment_photos.length; i++) {
    formData.append('verify_payment_photos[]', payload.verify_payment_photos[i]);
  }

  return request
    .post(`${api}/customers/payment-photo/validate`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data);
};

// // // // // // // // // // // // // // // OWNER ENDPOINTS // // // // // // // // // // // // // // //

export const getCustomers = () => ownerRequest
  .get(`${api}/customers`)
  .then(res => res.data);

export const getCarOptions = () => ownerRequest
  .get(`${api}/cars/options`)
  .then(res => res.data);

export const getLocationStates = () => ownerRequest
  .get(`${api}/rental_locations/states`)
  .then(res => res.data);

export const loginOwner = ({ googleCredential, appleCredentials, email, password, referralCode }: any) => ownerRequest
  .post(`${api}/admin/login`, {
    google_credential: googleCredential,
    apple_credentials: appleCredentials,
    email,
    password,
    referral_code: referralCode,
  })
  .then(res => res.data);

export const resetOwnerPasswordResetRequest = ({ email }: any) => ownerRequest
  .post(`${api}/admin/password-reset-request`, {
    email,
  })
  .then(res => res.data);

export const resetOwnerPasswordConfirm = ({ token, password }: any) => ownerRequest
  .post(`${api}/admin/password-reset-confirm`, {
    token,
    new_password: password,
  })
  .then(res => res.data);

export const getOwner = () => ownerRequest
  .get(`${api}/admins/me`)
  .then(res => res.data);

export const updateOwner = (payload: any) => ownerRequest
  .put(`${api}/admins/me/update`, payload)
  .then(res => res.data);

export const getOwnerReservation = (id: any) => ownerRequest
  .get(`${api}/reservations/${id}`)
  .then(res => res.data);

export const getAllOwnerCars = () => ownerRequest
  .get(`${api}/admins/cars`)
  .then(res => res.data);

export const getOwnerCars = ({ page, per_page, search }: any) => ownerRequest
  .get(`${api}/admins/cars?page=${page}&per_page=${per_page}&search=${search}`)
  .then(res => res.data);

export const getOwnerCar = (id: any) => ownerRequest
  .get(`${api}/admins/cars/${id}`)
  .then(res => res.data);

export const connectSmartcar = (code: string) => ownerRequest
  .post(`${api}/admins/cars/smartcar/connect`, { code })
  .then(res => res.data);

export const lockOwnerCar = (carId: number) => ownerRequest
  .post(`${api}/admins/cars/${carId}/lock`)
  .then(res => res.data);

export const unlockOwnerCar = (carId: number) => ownerRequest
  .post(`${api}/admins/cars/${carId}/unlock`)
  .then(res => res.data);

export const refreshOwnerCarState = (carId: number) => ownerRequest
  .post(`${api}/admins/cars/${carId}/state/refresh`)
  .then(res => {
    const data = { ...res.data };
    return { ...data, carId };
  });

export const getOwnerLocations = () => ownerRequest
  .get(`${api}/admins/locations`)
  .then(res => res.data);

export const addOwnerLocation = (payload: any) => ownerRequest
  .post(`${api}/admins/locations`, payload)
  .then(res => res.data);

export const getOwnerCleaners = () => ownerRequest
  .get(`${api}/admins/cleaners`)
  .then(res => res.data);

export const createOwnerCleaner = (payload: any) => ownerRequest
  .post(`${api}/admins/cleaners`, payload)
  .then(res => res.data);

export const updateOwnerCar = ({ payload, carId }: any) => ownerRequest
  .put(`${api}/admins/cars/${carId}`, payload)
  .then(res => res.data);

export const updateOwnerCarSoftDelete = (carId: any) => ownerRequest
  .put(`${api}/admins/cars/${carId}/soft_delete_request`)
  .then(res => res.data);

export const getOwnerCalendarReservations = ({ startDate, endDate, city }: any) => {
  const params = new URLSearchParams();
  params.append('start_date', startDate);
  params.append('end_date', endDate);
  if (city) {
    params.append('city', city);
  }
  return ownerRequest
    .get(`${api}/admins/reservations/calendar?${params.toString()}`)
    .then(res => res.data);
};

export const createOwnerReservationBlock = (payload: any) => ownerRequest
  .post(`${api}/admins/reservations/calendar/block`, payload)
  .then(res => res.data);

export const getOwnerUpcomingReservations = ({ page, per_page }: any) => ownerRequest
  .get(`${api}/admins/reservations/upcoming?page=${page}&per_page=${per_page}`)
  .then(res => res.data);

export const getOwnerReservationsToReview = () => ownerRequest
  .get(`${api}/admins/reservations/to_review`)
  .then(res => res.data);

export const getOwnerReservations = ({ page, per_page, search }: any) => ownerRequest
  .get(`${api}/admins/reservations?page=${page}&per_page=${per_page}&search=${search}`)
  .then(res => res.data);

export const getOwnerPayouts = () => ownerRequest
  .get(`${api}/admins/payouts`)
  .then(res => res.data);

export const getOwnerPayout = (payoutId: string) => ownerRequest
  .get(`${api}/admins/payouts/${payoutId}`)
  .then(res => res.data);

export const updateOwnerReservation = ({ payload, reservationId }: any) => {
  const formData = new FormData();

  Object.keys(payload).forEach(key => {
    if (Array.isArray(payload[key])) {
      payload[key].forEach((item: any, index: number) => {
        if (item instanceof File) {
          // If the item is a File object, append it to the FormData object
          formData.append(`${key}[${index}]`, item, item.name);
        } else if (typeof item === 'object' && item !== null) {
          // If the item is an object, convert it to a JSON string
          formData.append(`${key}[${index}]`, JSON.stringify(item));
        } else {
          // If the item is a primitive value, append it to the FormData object
          formData.append(`${key}[${index}]`, item);
        }
      });
    } else {
      formData.append(key, payload[key]);
    }
  });

  return ownerRequest
    .put(`${api}/admins/reservations/${reservationId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data);
};

export const cancelOwnerReservation = ({ payload, reservationId }: any) => ownerRequest
  .put(`${api}/admins/reservations/${reservationId}/cancel`, payload)
  .then(res => res.data);

export const getOwnerSubstituableCars = ({ start_time, end_time }: any) => ownerRequest
  .get(`${api}/admins/cars/substituable?start_time=${start_time}&end_time=${end_time}`)
  .then(res => res.data);

export const changeOwnerReservationCar = ({ payload, reservationId }: any) => ownerRequest
  .put(`${api}/admins/reservations/${reservationId}/change-car`, payload)
  .then(res => res.data);

export const createOwnerBugReport = (payload: any) => {
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('firstname', payload.firstname);
  formData.append('lastname', payload.lastname);
  formData.append('email', payload.email);
  formData.append('description', payload.description);

  return ownerRequest
    .post(`${api}/admins/reports`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data);
};

export const createOwnerIncidental = (payload: any) => {
  const formData = new FormData();

  for (let i = 0; i < payload.images.length; i++) {
    formData.append('files[]', payload.images[i]);
  }

  formData.append('offense_date', payload.offense_date);
  formData.append('reservation_id', payload.reservation_id);
  formData.append('category', payload.category);
  formData.append('amount', payload.amount);

  return ownerRequest
    .post(`${api}/admins/incidentals`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data);
};

export const verifyOwnerCarVirtualKey = (carId: number) => ownerRequest
  .post(`${api}/admins/cars/${carId}/verify-virtual-key`)
  .then(res => res.data);

export const getPartner = () => ownerRequest
  .get(`${api}/admins/organizations`)
  .then(res => res.data);

export const updatePartner = (payload: any) => {
  const formData = new FormData();

  formData.append('name', payload.name);
  formData.append('url_slug', payload.url_slug);
  formData.append('description', payload.description);
  formData.append('keywords', payload.keywords);

  if (payload.dark_logo) {
    formData.append('dark_logo', payload.dark_logo);
  }

  if (payload.light_logo) {
    formData.append('light_logo', payload.light_logo);
  }

  return ownerRequest
    .post(`${api}/admins/organizations`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(res => res.data);
};
