import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import mixpanel from 'mixpanel-browser';
import './styles.scss';

import variables from '../../../assets/scss/variables.scss';

import { useCarDetails } from '../../../hooks';
import { pages, routes } from '../../../pages';
import { getDiscountPrice, getPriceWithDiscount } from '../../../utils';
import { CARS_OPTIONS, DATES, DELIVERY_ADDRESSES, DISCOUNTS } from '../../../constants';
import { checkoutSelectors, uiSelectors } from '../../../redux/slices';

import { CarImages } from '../CarImages';
import { CarFeatures } from '../CarFeatures';
import { LoadingSpinner } from '../../LoadingSpinner';
import { PageTitle, Typography, Card, InputField, Button, Scrollable } from '../../common';
import { IDisplayDates, IExtra, IInsurance, IStoreCarsSearch } from '../../../types';
import { PoweredBy } from '../../PoweredBy';
import { getFormattedDateStringFromInput } from '../../../helpers/utils';
import MinInsuranceModal from './MinInsuranceModal';
import NoInsuranceModal from './NoInsuranceModal';

interface IProps {
  onGoBack: () => void;
  days: number;
  isDelivery: boolean;
}

const CarDetails = ({ onGoBack, days, isDelivery }: IProps) => {
  const bottomRef = useRef<any>();
  const navigate = useNavigate();
  const { city } = useParams();
  const { search, hash } = useLocation();
  const [isSizeMd, setIsSizeMd] = useState<boolean>(false);
  const extraData = useSelector(checkoutSelectors.selectExtraData) as [IExtra] | [];
  const insurances = useSelector(checkoutSelectors.selectInsurances) as [IInsurance] | [];
  const { dates, address } = useSelector(uiSelectors.selectCarsSearch) as IStoreCarsSearch;
  const scrollableData = useSelector(state => uiSelectors.selectScrollableData(state, 'carDetails'));
  const isMapFullSize = useSelector(uiSelectors.selectMapFullSize) as boolean;
  const displayDates = useSelector(uiSelectors.selectDisplayDates) as IDisplayDates;
  const needsDelivery = useSelector(uiSelectors.selectNeedsDelivery) as boolean;
  const [showMinInsuranceModal, setShowMinInsuranceModal] = useState(false);
  const [showNoInsuranceModal, setShowNoInsuranceModal] = useState(false);

  const {
    carDetails,
    totalPrice,
    setQueryData,
    selectedOptions,
    selectedInsurance,
    discountData,
    setDiscountData,
    redeemDiscount,
  } = useCarDetails();

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 200);
  }, []);

  const handleGoToCheckout = () => {
    const params = new URLSearchParams(search);
    if (params.has(DATES.START)) {
      params.set(DATES.START, getFormattedDateStringFromInput(new Date(params.get(DATES.START) || dates.startDate)));
    } else {
      params.set(DATES.START, getFormattedDateStringFromInput(
        displayDates.startDT || new Date(dates.startDate),
      ));
    }
    if (params.has(DATES.END)) {
      params.set(DATES.END, getFormattedDateStringFromInput(new Date(params.get(DATES.END) || dates.endDate)));
    } else {
      params.set(DATES.END, getFormattedDateStringFromInput(
        displayDates.endDT || new Date(dates.endDate),
      ));
    }
    if (address.deliveryTo && address.collectAt) {
      params.set(DELIVERY_ADDRESSES.DELIVERY_TO, address.deliveryTo.placeId);
      params.set(DELIVERY_ADDRESSES.COLLECT_AT, address.collectAt.placeId);
    }
    params.set('city', String(city));
    navigate({
      hash,
      search: `?${params.toString()}`,
      pathname: routes[pages.checkout].replace(':city', String(city))
    });
  };

  const handleSetWidth = () => {
    // @ts-ignore
    // document.querySelector('.app-map').style.width = 'calc(100% - 800px)';
    if (window.innerWidth > Number(variables.sizemd)) {
      // @ts-ignore
      // document.querySelector('.app-data').style.width = '800px';
      // @ts-ignore
      // document.querySelector('.app-data').style.overflow = 'hidden';
      setIsSizeMd(true);
    } else {
      // @ts-ignore
      // document.querySelector('.app-data').style.width = '';
      // @ts-ignore
      // document.querySelector('.app-data').style.overflow = '';
      setIsSizeMd(false);
    }
  };

  useEffect(() => {
    handleSetWidth();
    window.addEventListener('resize', handleSetWidth);
    return () => {
      window.removeEventListener('resize', handleSetWidth);
      // @ts-ignore
      // document.querySelector('.app-map').style.width = 'calc(100% - 360px)';
      // @ts-ignore
      // document.querySelector('.app-data').style.width = '';
    };
  }, []);

  if (!carDetails) {
    return null;
  }

  return (
    <div
      className="car-details-container"
    >
      {/* <MinInsuranceModal
        isOpen={showMinInsuranceModal}
        onClose={() => setShowMinInsuranceModal(false)}
        onContinueClick={() => {
          setShowMinInsuranceModal(false);
        }}
        onUpgradeClick={() => {
          setShowMinInsuranceModal(false);

          setQueryData(
            CARS_OPTIONS.INSURANCE,
            insurances.find((insurance: IInsurance) => insurance.tag === 'standard')?.id,
          )();
        }}
      />

      <NoInsuranceModal
        isOpen={showNoInsuranceModal}
        onClose={() => setShowNoInsuranceModal(false)}
        onContinueClick={() => {
          setShowNoInsuranceModal(false);
        }}
        onUpgradeClick={() => {
          setShowNoInsuranceModal(false);

          setQueryData(
            CARS_OPTIONS.INSURANCE,
            insurances.find((insurance: IInsurance) => insurance.tag === 'minimum')?.id,
          )();
        }}
      /> */}

      <PageTitle
        title={carDetails?.model_name}
        className={cx('p-4', { 'box-shadow2': scrollableData?.scrollTop > 0 })}
        onGoBack={onGoBack}
        showPlaceholder={!carDetails}
      >
        <div className="d-flex align-items-end flex-column">
          <Typography variant="h3" className="m-0">
            {carDetails.daily_rate !== getPriceWithDiscount(carDetails, Number(days || 1)) ? (
              <>
                <span className="text-decoration-line-through me-1" style={{ fontSize: 12 }}>
                  {carDetails.daily_rate}
                </span>
                <span className="text-danger">
                  {`$${getPriceWithDiscount(carDetails, Number(days || 1))} / day`}
                </span>
              </>
            ) : (
              `$${getPriceWithDiscount(carDetails, Number(days || 1))} / day`
            )}
          </Typography>
          <Typography variant="body3">
            {`$${totalPrice.toFixed(2)} / total`}
          </Typography>
        </div>
      </PageTitle>
      <Scrollable id="carDetails" className="px-4" key={carDetails.id}>
        <CarImages className="mb-3" images={carDetails.thumbnails} />
        {!needsDelivery
          && (
            <Typography variant="body3" className="mb-3">
              {`Address: ${carDetails.default_location.address}`}
            </Typography>
          )}
        {needsDelivery
          && (
            <Typography variant="body3" className="mb-3">
              {`Delivery to: ${carDetails.delivery_address}`}
            </Typography>
          )}
        <Typography variant="body2" className="mb-3">{carDetails.description}</Typography>
        <CarFeatures car={carDetails} />
        <Typography variant="h2" className="mt-5 mb-3">Included</Typography>
        <Card className="hover mb-2 radius-small">
          <Typography variant="h3" className="m-0 d-flex justify-content-between gap-2">
            <span>{`${carDetails.model_name} ${carDetails.model_type}`}</span>
            <span className="text-nowrap">
              {carDetails.daily_rate !== getPriceWithDiscount(carDetails, Number(days || 1)) ? (
                <>
                  <span className="text-decoration-line-through me-1" style={{ fontSize: 12 }}>
                    {carDetails.daily_rate}
                  </span>
                  <span className="text-danger">
                    {`$${getPriceWithDiscount(carDetails, Number(days || 1))} / day`}
                  </span>
                </>
              ) : (
                `$${carDetails.daily_rate} / day`
              )}
            </span>
          </Typography>
        </Card>
        {isDelivery && (
          <>
            <Card className="hover">
              <Typography variant="h3" className="m-0 d-flex justify-content-between gap-2">
                {extraData.find((item: IExtra) => item.title === CARS_OPTIONS.DELIVERY.id) ? (
                  <>
                    <span>
                      Delivery fee
                    </span>
                    <span className="text-nowrap">
                      {`+${Math.round(carDetails.delivery_cost)}`}
                    </span>
                  </>
                ) : (
                  <>
                    <span>{CARS_OPTIONS.DELIVERY.title}</span>
                    <span className="text-nowrap">{`+${Math.round(carDetails.delivery_cost)}`}</span>
                  </>
                )}
              </Typography>
              <Typography variant="body3" className="text-secondary mt-2">
                {`Delivery address: ${carDetails.delivery_address}`}
              </Typography>
              <Typography variant="body3" className="text-secondary">
                {`Return address: ${carDetails.return_address}`}
              </Typography>
            </Card>
            <Typography variant="body2" className="mt-3 text-secondary">
              Your agent will get in touch with you to coordinate your delivery after booking
            </Typography>
          </>
        )}
        <Typography variant="h2" className="mt-5 mb-3">Choose Insurance</Typography>
        {insurances.map((insurance: IInsurance) => (
          <Card
            key={`${insurance.id}-${carDetails.id}`}
            className={cx('mb-2', { hover: insurance.id === selectedInsurance })}
            type="radio"
            name="insurance"
            onChange={() => {
              if (insurance.tag === 'minimum') {
                setShowMinInsuranceModal(true);
              } else if (insurance.tag === 'customer') {
                setShowNoInsuranceModal(true);
              }

              setQueryData(
                CARS_OPTIONS.INSURANCE,
                insurance.id,
              )();

              mixpanel.track('Button Clicked', {
                'Button Name': 'Insurance Add-On',
                'Add-On Type': CARS_OPTIONS.INSURANCE,
                'Add-On Insurance Category': insurance.category,
                'Add-On Insurance Percentage': insurance.percentage,
              });
            }}
            checked={insurance.id === selectedInsurance}
          >
            <Typography
              variant="h3"
              className="m-0 d-flex justify-content-between gap-2 mb-1"
              style={{ fontSize: '16px' }}
            >
              <span>{insurance.category}</span>
              <span className={cx('text-nowrap', { 'text-disabled': insurance.id !== selectedInsurance })}>
                {insurance.percentage
                  ? `+$${(carDetails.daily_rate * (insurance.percentage / 100)).toFixed(2)} / day`
                  : 'Free'}
              </span>
            </Typography>
            {
              insurance.meta && insurance.meta?.subtitle && (
                <Typography variant="h4" className="m-0" style={{ fontSize: '16px', lineHeight: '20px' }}>
                  {insurance.meta.subtitle}
                </Typography>
              )
            }
            {
              insurance.meta && insurance.meta.descriptions && (
                <ul style={{ padding: 0, paddingLeft: '16px', margin: 0, marginTop: '10px', fontSize: '14px' }}>
                  {insurance.meta.descriptions.map((item: string) => (
                    <li
                      key={item}
                      className="text-secondary mb-1"
                      style={{ lineHeight: '18px' }}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              )
            }
          </Card>
        ))}
        {(extraData.length || CARS_OPTIONS.ENABLE_DISCOUNT) && (
          <Typography variant="h2" className="mt-5 mb-3">Anything Else?</Typography>
        )}
        {extraData.filter((item: IExtra) => item.title !== CARS_OPTIONS.DELIVERY.id).map((item: IExtra) => (
          <Card
            key={item.id}
            className={cx('mb-2', { hover: selectedOptions.includes(item.id as never) })}
            type="checkbox"
            name="options"
            onChange={() => {
              const newOptions = [...selectedOptions];
              const index = newOptions.indexOf(item.id);
              if (index > -1) {
                newOptions.splice(index, 1);
              } else {
                newOptions.push(item.id);
              }
              setQueryData(
                CARS_OPTIONS.OPTIONS,
                newOptions,
              )();

              const selectedExtras = extraData
                .filter((extra: IExtra) => newOptions.includes(extra.id))
                .sort((extra: IExtra) => extra.id)
                .map((extra: IExtra) => `${extra.title}-${extra.amount}-${extra.is_recurring}`);

              mixpanel.track('Button Clicked', {
                'Button Name': 'Extra Add-On',
                'Add-On Type': CARS_OPTIONS.OPTIONS,
                'Add-On Extras': selectedExtras,
              });
            }}
            checked={selectedOptions.includes(item.id as never)}
          >
            <Typography variant="h3" className="m-0 d-flex justify-content-between gap-2">
              <span>{item.title}</span>
              <span className={cx('text-nowrap', { 'text-disabled': !selectedOptions.includes(item.id as never) })}>
                {item.amount && item.recurring ? `+$${item.amount} / day` : ''}
                {item.amount && !item.recurring ? `+$${item.amount}` : ''}
                {!item.amount ? 'Free' : ''}
              </span>
            </Typography>
            <Typography variant="body2" className="text-secondary mb-0">
              {item.description}
            </Typography>
          </Card>
        ))}
        {CARS_OPTIONS.ENABLE_DISCOUNT && (
          <Card
            className={cx('mb-2', 'focus-disable', 'active-disable', 'position-relative', {
              hover: discountData.opened
            })}
            type="checkbox"
            name="discount"
            onChange={() => {
              if (!discountData.opened) {
                mixpanel.track('Button Clicked', {
                  'Button Name': 'Discount Box',
                  'Discount Box Visible': true,
                });
              }
              setDiscountData({ clear: true, opened: !discountData.opened });
            }}
            checked={discountData.opened}
          >
            <Typography variant="h3" className="m-0 d-flex justify-content-between gap-3">
              <span>Add discount code</span>
              {Boolean(discountData.amount) && (discountData.opened) && (
                <span className="text-danger">
                  {`-$${discountData.amount}`}
                </span>
              )}
            </Typography>
            {discountData.isChecking && <LoadingSpinner absolute />}
            {discountData.opened && (
              <>
                <InputField
                  label="Enter code"
                  className="mt-2"
                  autoComplete="off"
                  onChange={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setDiscountData({ ...discountData, code: e.target.value, error: null });
                  }}
                  value={discountData.code}
                  error={discountData.error ? ' Discount code not found' : undefined}
                />
                <Button
                  className="mt-2"
                  onClick={redeemDiscount}
                >
                  Redeem code
                </Button>
              </>
            )}
          </Card>
        )}
        <br />
        <PoweredBy className="d-md-none" />

        {/* <div style={{ paddingBottom: Math.max((bottomRef.current?.clientHeight || 120) + 20, 140) }} /> */}
        <div style={{ paddingBottom: 20 }} />
      </Scrollable>
      <div
        ref={bottomRef}
        className="with-background box-shadow-filter px-4 pb-4 d-flex flex-column"
        // style={{ bottom: 0, width: isSizeMd ? 400 : '100vw', opacity: isMapFullSize ? 0 : 1 }}
        style={{ bottom: 0, opacity: isMapFullSize ? 0 : 1, height: '130px', width: '100%' }}
      >
        <Typography variant="h3" className={cx('d-flex justify-content-between mt-3', { 'mb-0': discountData.amount })}>
          <span>{`Total for ${days} ${days === 1 ? 'day' : 'days'}`}</span>
          <span>{`$${totalPrice.toFixed(2)}`}</span>
        </Typography>
        {Boolean(discountData.amount) && (discountData.opened) && (
          <Typography variant="body2" className="d-flex justify-content-between text-danger mb-3">
            <span>You saved</span>
            <span>{`$${discountData.amount}`}</span>
          </Typography>
        )}
        {days >= Number(DISCOUNTS.find((d: any) => d.key === 'week')?.daysMore) && (
          <Typography variant="body2" className="d-flex justify-content-between text-danger mb-3">
            <span>
              {days < Number(DISCOUNTS.find(d => d.key === 'week')?.daysLess || 0)
                ? 'Weekly Discount'
                : 'Monthly Discount'}
            </span>
            <span>{getDiscountPrice(carDetails, days, true)}</span>
          </Typography>
        )}
        <Button
          variant="primary"
          onClick={handleGoToCheckout}
          className="w-100 w-md-fit row-b2 row-none"
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export { CarDetails };
