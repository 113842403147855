import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Icon } from '@mui/material';
import { useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import Zoom from 'react-medium-image-zoom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-medium-image-zoom/dist/styles.css';
import { Document, Page, pdfjs } from 'react-pdf';
import './styles.scss';

import { ownerActions, ownerSelectors } from 'redux/slices';
import { formatCar } from 'redux/helpers';
import { IReservation, APIStatus, IOwner, IIncidental, IToll, IImage, IChargingRecord, CarStatusEnum } from 'types';
import * as api from 'api';
import { Button, InputField, Typography } from 'components/common';
import { Section } from 'components/common/Section';
import TripSummary from 'components/Owner/Reservations/TripSummary';
import { Input } from 'components/common/Input';
import Dropzone from 'components/common/Dropzone';
import variables from 'assets/scss/variables.scss';
import { capitalizeFirstChar, snakeToTitleCase } from 'helpers/utils';
import BackButton from 'components/common/BackButton';
import { LoadingSpinner } from 'components/LoadingSpinner';
import AddVirtualKeyBanner from 'components/Owner/Cars/AddVirtualKeyBanner';
import SmartcarConnect from 'components/Owner/SmartcarConnect';
import { getResColor } from 'helpers/reservations';
import SmartcarUserDriverBanner from 'components/Owner/Cars/SmartcarUserDriverBanner';
import ModifyReservation from './ModifyReservation';
import AddIncidentalForm from './AddIncidental';
import AddTolls from './AddTolls';
import AddChargingRecord from './AddChargingRecord';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const EditReservation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reservationId } = useParams();
  const owner = useSelector(ownerSelectors.selectOwner) as IOwner;
  const [res, setRes] = useState<IReservation>();
  const [showModifyReservation, setShowModifyReservation] = useState(false);
  const [showAddIncidentalModal, setShowAddIncidentalModal] = useState(false);
  const [showAddTollsModal, setShowAddTollsModal] = useState(false);
  const [showChargingRecordsModal, setShowChargingRecordsModal] = useState(false);
  const [imagesToUpload, setImagesToUpload] = useState<File[]>();
  const [lockCarStatus, setLockCarStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const [unlockCarStatus, setUnlockCarStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const [updateTripStatus, setUpdateTripStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });
  const [cancelBlockedTripStatus, setCancelBlockedTripStatus] = useState<APIStatus>({
    loading: false,
    success: false,
    error: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await api.getOwnerReservation(reservationId);
        setRes({
          ...data,
          car: formatCar(data.car),
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const formikForm = useFormik({
    initialValues: {
      starting_mileage: res?.starting_mileage,
      ending_mileage: res?.ending_mileage,
      charging_used: res?.charging_used,
      host_notes: res?.host_notes,
      owner_discount: res?.owner_discount,
      car_images: imagesToUpload || [],
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: async vals => {
      const payload = {
        ...vals,
      };

      setUpdateTripStatus({ loading: true, success: false, error: null });

      try {
        const data = await api.updateOwnerReservation({ payload, reservationId: res!.id });
        setUpdateTripStatus({ loading: false, success: true, error: null });
        dispatch(ownerActions.updateReservation.success(data));
        setRes(data);
        setImagesToUpload([]);
        toast.success('Reservation updated successfully');
      } catch (error: any) {
        setUpdateTripStatus({
          loading: false,
          success: false,
          error: { code: error.response.status, message: error.response.data.error },
        });
        toast.error(error.response.data.error);
      }
    },
    enableReinitialize: true,
  });

  const allowUploadingCarImages = useMemo(() => {
    if (!res) return false;

    const now = moment();
    const startTime = moment(res.start_time);
    const endTime = moment(res.end_time);

    const validBeforeTrip = now.isAfter(startTime.clone().subtract(24, 'hours'))
      && now.isBefore(startTime.clone().add(6, 'hours'));
    const validAfterTrip = now.isAfter(endTime.clone().subtract(1, 'hours'))
      && now.isBefore(endTime.clone().add(24, 'hours'));

    return validBeforeTrip || validAfterTrip;
  }, [res?.start_time, res?.end_time]);

  const allowUploadingChargingRecordImages = useMemo(() => {
    if (!res) return false;

    const now = moment();

    const dayAfterTripEnds = moment(res.end_time).add(24, 'hours');

    return now.isBefore(dayAfterTripEnds) && now.isAfter(res.end_time) && now.isAfter(res.start_time);
  }, [res?.end_time]);

  const allowUploadingTollImages = useMemo(() => {
    if (!res) return false;

    const now = moment();

    const threeDayAfterTripEnds = moment(res.end_time).add(72, 'hours');

    return now.isBefore(threeDayAfterTripEnds) && now.isAfter(res.end_time) && now.isAfter(res.start_time);
  }, [res?.end_time]);

  const beforeTripPhotos = useMemo(() => {
    if (!res) return [];

    return res.car_images.filter((i) => moment(i.created_at).isBefore(moment(res.end_time).subtract(3, 'hours')));
  }, [res?.car_images]);

  const afterTripPhotos = useMemo(() => {
    if (!res) return [];

    return res.car_images.filter((i) => moment(i.created_at).isAfter(moment(res.end_time).subtract(3, 'hours')));
  }, [res?.car_images]);

  const handleCarImagesChanged = (files: any[]) => {
    if (!res) return;

    const MAX_FILES = 40;

    if ((res.car_images.length + files.length) >= MAX_FILES) {
      // eslint-disable-next-line no-alert
      alert(`You can only upload a maximum of ${MAX_FILES} files`);
      return;
    }

    setImagesToUpload(files);
  };

  if (owner?.is_fully_managed) {
    window.location.href = '/owner/reservations';
  }

  if (!res) return null;

  const handleLock = async () => {
    setLockCarStatus({ loading: true, success: false, error: null });

    try {
      await api.lockOwnerCar(res.car.id);
      setLockCarStatus({ loading: false, success: true, error: null });
      toast.success('Car locked successfully');
    } catch (error: any) {
      setLockCarStatus({
        loading: false,
        success: false,
        error: { code: error.response.status, message: error.response.data.error },
      });
      toast.error(error.response.data.error);
    }
  };

  const handleUnlock = async () => {
    setUnlockCarStatus({ loading: true, success: false, error: null });

    try {
      await api.unlockOwnerCar(res.car.id);
      setUnlockCarStatus({ loading: false, success: true, error: null });
      toast.success('Car unlocked successfully');
    } catch (error: any) {
      setUnlockCarStatus({
        loading: false,
        success: false,
        error: { code: error.response.status, message: error.response.data.error },
      });
      toast.error(error.response.data.error);
    }
  };

  const cancelBlockedTrip = async () => {
    setCancelBlockedTripStatus({ loading: true, success: false, error: null });

    try {
      await api.updateOwnerReservation({ payload: { status: 'cancelled' }, reservationId: res!.id });
      setCancelBlockedTripStatus({ loading: false, success: true, error: null });
      toast.success('Trip cancelled successfully');

      setTimeout(() => {
        navigate(-1);
      }, 2000);
    } catch (error: any) {
      setCancelBlockedTripStatus({
        loading: false,
        success: false,
        error: { code: error.response.status, message: error.response.data.error },
      });
      toast.error(error.response.data.error);
    }
  };

  return (
    <div
      className="d-flex flex-column w-100 align-items-center"
      style={{ padding: '29px' }}
    >
      <ToastContainer autoClose={2500} />

      {
        (
          lockCarStatus.loading
          || unlockCarStatus.loading
          || updateTripStatus.loading
          || cancelBlockedTripStatus.loading
        ) && (
          <LoadingSpinner absolute />
        )
      }

      <ModifyReservation
        reservation={res}
        isOpen={showModifyReservation}
        onCancelledTrip={(newRes: IReservation) => setRes(newRes)}
        onChangeCar={(newRes: IReservation) => setRes(newRes)}
        onClose={() => setShowModifyReservation(false)}
      />

      <AddIncidentalForm
        reservation={res}
        isOpen={showAddIncidentalModal}
        onClose={() => setShowAddIncidentalModal(false)}
        onCreate={(v: IIncidental) => setRes({ ...res, incidentals: [...res.incidentals, v] })}
      />

      <div
        className="d-flex flex-column justify-content-center"
        style={{ maxWidth: '560px', width: '100%' }}
      >
        <div className="d-flex flex-row justify-content-space-between gap-4">
          <div className="d-flex flex-column gap-1">
            <BackButton />

            <Typography variant="h2" style={{ margin: 0 }}>
              {res.customer_name}
            </Typography>

            <Link
              to={`/owner/cars/${res.car.id}/edit`}
              style={{ fontSize: '20px', color: variables.blue1 }}
            >
              {res.car.license_plate}
            </Link>

            {
              res.custom_pickup_location
                ? (
                  <div className="d-flex flex-column gap-2">
                    <Typography style={{ margin: 0 }}>
                      {`Delivery Pickup Location: ${res.custom_pickup_location}`}
                    </Typography>

                    <Typography style={{ margin: 0 }}>
                      {`Delivery Return Location: ${res.custom_return_location}`}
                    </Typography>
                  </div>
                ) : (
                  <Typography style={{ margin: 0 }}>
                    {res.pickup_location.address}
                  </Typography>
                )
            }

            <Typography className="m-0 p-0" style={{ color: getResColor(res) }} variant="h3">
              {
                capitalizeFirstChar(res.status)
              }
            </Typography>
          </div>

          <div className="d-flex flex-column gap-1">
            <Typography variant="h3" style={{ margin: 0 }}>
              {
                moment.tz(res.start_time, res.pickup_location.timezone).format('MM/DD/YY, h:mma z')
              }
            </Typography>

            <Typography style={{ alignSelf: 'flex-end' }}>
              {`ID # ${res.id}`}
            </Typography>
          </div>
        </div>

        <div className="d-flex flex-column align-items-center gap-2" style={{ marginBottom: '6px', width: '100%' }}>
          <SmartcarUserDriverBanner car={res.car} style={{ width: '350px' }} />

          <AddVirtualKeyBanner
            car={res.car}
            onSuccess={() => setRes({ ...res, car: { ...res.car, smartcar_virtual_key_connected: true } })}
            style={{ width: '350px' }}
          />
        </div>

        {
          res.car.status === CarStatusEnum.NotConnected && (
            <div className="mt-2 mb-2 text-center">
              <SmartcarConnect
                text="Connect Car"
                style={{
                  boxShadow: 'none',
                  color: variables.red2,
                  fontSize: '26px',
                }}
                className="m-0 p-0"
              />
            </div>
          )
        }

        <div className="mt-2">
          <Section title="Trip Summary">
            <div className="mt-4">
              <TripSummary res={res} />
            </div>
          </Section>
        </div>

        <div className="d-flex flex-row mt-4 gap-4">
          <div className="d-flex flex-row gap-3">
            <div
              data-tooltip-id={
                (!res.car.smartcar_virtual_key_connected && 'car-smartcar-virtual-key-disconnected') || ''
              }
              data-tooltip-html={`
                Add a digital key to Smartcar<br/> to lock and unlock your car remotely`}
            >
              <Button
                variant="default"
                className=""
                type="submit"
                style={{
                  backgroundColor: variables.gray5,
                  width: '90px',
                  boxShadow: 'none',
                  padding: '10px',
                }}
                onClick={handleLock}
                disabled={!res.car.smartcar_virtual_key_connected}
              >
                <Icon>
                  lock
                </Icon>

                <Typography variant="body2" className="text-center">
                  Lock
                </Typography>
              </Button>
            </div>

            <div
              data-tooltip-id={
                (!res.car.smartcar_virtual_key_connected && 'car-smartcar-virtual-key-disconnected') || ''
              }
              data-tooltip-html={`
                Add a digital key to Smartcar<br/> to lock and unlock your car remotely`}
            >
              <Button
                variant="default"
                type="submit"
                style={{
                  backgroundColor: variables.gray5,
                  width: '90px',
                  boxShadow: 'none',
                  padding: '10px',
                }}
                onClick={handleUnlock}
                disabled={!res.car.smartcar_virtual_key_connected}
              >
                <Icon>
                  lock_open
                </Icon>

                <Typography variant="body2" className="text-center">
                  Unlock
                </Typography>
              </Button>
            </div>

            <Tooltip id="car-smartcar-virtual-key-disconnected" />

          </div>

          <div className="flex-grow-1 d-flex">
            <Button
              variant="default"
              type="submit"
              style={{
                backgroundColor: variables.red4,
                boxShadow: 'none',
                padding: '10px',
                width: '100%',
                minWidth: '90px',
              }}
              onClick={() => setShowModifyReservation(true)}
              disabled={['cleaning', 'completed', 'billable'].includes(res.status)}
            >
              <Icon>
                edit_road
              </Icon>

              <Typography variant="body2" className="text-center">
                Modify Trip
              </Typography>
            </Button>
          </div>

        </div>

        <div className="d-flex flex-column gap-2 mt-4">
          <div>
            <InputField
              label="Start time"
              name="Start time"
              value={moment.tz(res.start_time, res.pickup_location.timezone).format('MMM DD - h:mma z')}
              disabled
              required
            />
          </div>

          <div>
            <InputField
              label="End time"
              name="End time"
              value={moment.tz(res.end_time, res.return_location.timezone).format('MMM DD - h:mma z')}
              disabled
              required
            />
          </div>
        </div>

        <div className="mt-4">
          <Section title="Guest Details">
            <div className="mt-4 d-flex flex-column gap-2">
              {
                ['confirmed', 'rental', 'cleaning'].includes(res.status)
                  ? (
                    <>
                      <Typography variant="h3" style={{ margin: 0 }}>
                        {res.customer_name}
                      </Typography>

                      <Typography style={{ margin: 0 }}>
                        {res.customer_phone}
                      </Typography>

                      <Typography style={{ margin: 0 }}>
                        {res.customer_email}
                      </Typography>
                    </>
                  ) : (
                    <Typography style={{ margin: 0 }}>
                      Customer details will become visible once the trip begins
                    </Typography>
                  )
              }
            </div>
          </Section>
        </div>

        <div className="mt-4">
          <Section title="Trip Information">
            <div className="mt-4 d-flex flex-row gap-2">
              <div style={{ width: '50%' }}>
                <InputField
                  label="Starting Miles"
                  type="number"
                  name="starting_mileage"
                  value={formikForm.values.starting_mileage || ''}
                  error={formikForm.touched.starting_mileage && formikForm.errors.starting_mileage}
                  onChange={formikForm.handleChange}
                />
              </div>

              <div style={{ width: '50%' }}>
                <InputField
                  label="Ending Miles"
                  type="number"
                  name="ending_mileage"
                  value={formikForm.values.ending_mileage || ''}
                  error={formikForm.touched.ending_mileage && formikForm.errors.ending_mileage}
                  onChange={formikForm.handleChange}
                />
              </div>
            </div>

            <div className="mt-4 d-flex flex-row gap-2">
              <div style={{ width: '50%' }}>
                <InputField
                  label="Owner Discount"
                  type="number"
                  name="owner_discount"
                  value={formikForm.values.owner_discount || ''}
                  error={formikForm.touched.owner_discount && formikForm.errors.owner_discount}
                  onChange={formikForm.handleChange}
                />
              </div>
            </div>

            <div className="mt-4 d-flex flex-row">
              <Input
                type="textbox"
                onChange={formikForm.handleChange}
                value={formikForm.values.host_notes || ''}
                placeholder="Owner Notes"
                name="host_notes"
                style={{ height: '150px', resize: 'none' }}
              />
            </div>
          </Section>
        </div>

        <div className="mt-4">
          <Section title="Photos">
            <div
              className="mt-4 d-flex flex-column"
            >
              <div>
                <Typography variant="h2">
                  Upload Photos
                </Typography>

                <Typography>
                  Upload photos of the car to confirm its condition. (40 photos max)
                </Typography>

                {
                  allowUploadingCarImages
                    ? (
                      <>
                        <div>
                          <Typography variant="h3">
                            Remember to hit the update button at the bottom to save photos.
                          </Typography>
                        </div>
                        <Dropzone
                          accept={{
                            'image/png': ['.png'],
                            'image/jpeg': ['.jpg', '.jpeg'],
                          }}
                          multiple
                          onImagesChanged={handleCarImagesChanged}
                          defaultFiles={imagesToUpload || []}
                          previewStyle={{
                            width: '100px',
                          }}
                          convertImgToJpeg
                        />
                      </>
                    ) : (
                      <Typography>
                        Photo submission is currently disabled.
                        You can only modify photos 24 hours before a trip begins or 24 hours after the trip ends.
                      </Typography>
                    )
                }
              </div>

              {
                res.car_images && res.car_images.length > 0 && (
                  <div
                    className="mt-2 d-flex flex-column gap-4"
                  >
                    <div>
                      <Typography variant="h2">
                        Pre-trip Photos
                      </Typography>

                      <Slider
                        {
                        ...{
                          dots: true,
                          infinite: beforeTripPhotos.length > 3,
                          speed: 500,
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          lazyLoad: 'anticipated',
                        }
                        }
                      >
                        {
                          beforeTripPhotos
                            .map((image: IImage) => {
                              return (
                                <div
                                  key={image.photo}
                                >
                                  <Zoom>
                                    <img
                                      src={image.photo}
                                      alt={image.photo}
                                      style={{
                                        width: '200px',
                                        objectFit: 'contain',
                                        margin: '0 auto',
                                      }}
                                    />
                                  </Zoom>
                                  <div className="mt-2">
                                    {
                                      moment(image.created_at)
                                        .tz(res.pickup_location.timezone)
                                        .format('MM/DD/YYYY HH:mm:ss z')
                                    }
                                    {
                                      owner?.is_superadmin && (
                                        <div>
                                          {`Uploaded by ${image.user_name}`}
                                        </div>
                                      )
                                    }
                                  </div>
                                </div>

                              );
                            })
                        }
                      </Slider>
                    </div>

                    <div>
                      <Typography variant="h2">
                        Post-trip Photos
                      </Typography>

                      <Slider
                        {
                        ...{
                          dots: true,
                          infinite: afterTripPhotos.length > 3,
                          speed: 500,
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          lazyLoad: 'anticipated',
                        }
                        }
                      >
                        {
                          afterTripPhotos
                            .map((image: IImage) => {
                              return (
                                <div
                                  key={image.photo}
                                >
                                  <Zoom>
                                    <img
                                      src={image.photo}
                                      alt={image.photo}
                                      style={{
                                        width: '200px',
                                        objectFit: 'contain',
                                        margin: '0 auto',
                                      }}
                                    />
                                  </Zoom>
                                  <div className="mt-2">
                                    {
                                      moment(image.created_at)
                                        .tz(res.pickup_location.timezone)
                                        .format('MM/DD/YYYY HH:mm:ss z')
                                    }
                                    {
                                      owner?.is_superadmin && (
                                        <div>
                                          {`Uploaded by ${image.user_name}`}
                                        </div>
                                      )
                                    }
                                  </div>
                                </div>

                              );
                            })
                        }
                      </Slider>
                    </div>

                  </div>
                )
              }

            </div>
          </Section>
        </div>

        <div className="mt-4">
          <Section title="Tolls">
            <div className="mt-4 d-flex flex-column gap-2 align-items-center">
              <div className="d-flex flex-row w-100">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th>Toll Plaza Name</th>
                      <th>Time</th>
                      <th>Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      res.tolls?.map((toll: IToll) => (
                        <tr key={toll.id}>
                          <td>{toll.name}</td>
                          <td>{toll.date}</td>
                          <td>{`$${toll.amount}`}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              <div className="align-self-end">
                <Typography>
                  {
                    `Total: $${res.tolls?.reduce((acc, curr) => acc + parseFloat(curr.amount), 0).toFixed(2)}`
                  }
                </Typography>
              </div>

              <div>
                <div
                  className="d-flex gap-2 align-items-center"
                >
                  <Button
                    className="d-flex gap-2"
                    onClick={() => {
                      const message = `
                            You're opting to not use our automated tolls feature.
                            You'll need to manually input all your own tolls
                            as well as provide evidence for each of them.
                            Continue?
                          `.trim().replace(/\s+/g, ' ');

                      // eslint-disable-next-line no-alert
                      if (window.confirm(message)) {
                        setShowAddTollsModal(true);
                      }
                    }}
                    disabled={!allowUploadingTollImages}
                  >
                    <Icon>lock</Icon>
                    Modify Tolls
                  </Button>

                  <Icon
                    data-tooltip-id="modify-tolls"
                    data-tooltip-html={`
                    You can only modify tolls for 72 hours<br/> after the end of the trip`}
                  >
                    info
                  </Icon>
                </div>

                <Tooltip id="modify-tolls" />
              </div>

              {
                res.toll_images && res.toll_images.length > 0 && (
                  <div
                    className="mt-3 w-100"
                    style={{ maxWidth: '90%' }}
                  >
                    <Slider
                      {
                      ...{
                        dots: true,
                        infinite: res.toll_images?.length > 3,
                        speed: 500,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        lazyLoad: 'anticipated',
                      }
                      }
                    >
                      {
                        res.toll_images?.map((imageURI: string) => {
                          const isPdf = imageURI.endsWith('.pdf');

                          return (
                            <div key={imageURI}>
                              {
                                isPdf
                                  ? (
                                    <div
                                      onDoubleClick={() => {
                                        window.open(imageURI, '_blank');
                                      }}
                                    >
                                      <Document file={imageURI}>
                                        <Page
                                          pageNumber={1}
                                          width={100}
                                          scale={1.5}
                                          renderAnnotationLayer={false}
                                          renderTextLayer={false}
                                        />
                                      </Document>
                                    </div>
                                  ) : (
                                    <Zoom>
                                      <img
                                        src={imageURI}
                                        alt={imageURI}
                                        style={{
                                          width: '100px',
                                          maxHeight: '300px',
                                          margin: '0 auto',
                                        }}
                                      />
                                    </Zoom>
                                  )
                              }
                            </div>
                          );
                        })
                      }
                    </Slider>
                  </div>
                )
              }

              <div>
                <a
                  href="https://www.notion.so/eonrides/Troubleshooting-65effe444dc545d49c5a6bde69fbe050"
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontSize: '14px', textAlign: 'center' }}
                >
                  Missing items? Follow these steps...
                </a>
              </div>
            </div>
          </Section>
        </div>

        <div className="mt-4">
          <Section title="Charging Records">
            <div className="mt-4 d-flex flex-column gap-2 align-items-center">
              <div className="d-flex flex-row w-100">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th>Charging Plaza Name</th>
                      <th>Time</th>
                      <th>Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      res.charging_records?.map((chargingRecord: IChargingRecord) => (
                        <tr key={chargingRecord.id}>
                          <td>{chargingRecord.name}</td>
                          <td>{chargingRecord.start_timestamp}</td>
                          <td>{`$${chargingRecord.amount}`}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              <div className="align-self-end">
                <Typography>
                  {
                    `
                    Total: $${res.charging_records?.reduce((acc, curr) => acc + parseFloat(curr.amount), 0).toFixed(2)}
                    `
                  }
                </Typography>
              </div>

              <div>
                <div
                  className="d-flex gap-2 align-items-center"
                >
                  <Button
                    className="d-flex gap-2"
                    onClick={() => {
                      const message = `
                            You're opting to not use our automated charging records feature.
                            You'll need to manually input all your own charging records
                            as well as provide evidence for each of them.
                            Continue?
                          `.trim().replace(/\s+/g, ' ');

                      // eslint-disable-next-line no-alert
                      if (window.confirm(message)) {
                        setShowChargingRecordsModal(true);
                      }
                    }}
                    disabled={!allowUploadingChargingRecordImages}
                  >
                    <Icon>lock</Icon>
                    Modify Charging Records
                  </Button>

                  <Icon
                    data-tooltip-id="modify-charging-records"
                    data-tooltip-html={`
                    You can only modify charging records<br/> for 24 hours after the end of the trip`}
                  >
                    info
                  </Icon>
                </div>

                <Tooltip id="modify-charging-records" />
              </div>

              {
                res.charging_record_images && res.charging_record_images.length > 0 && (
                  <div
                    className="mt-3 w-100"
                    style={{ maxWidth: '90%' }}
                  >
                    <Slider
                      {
                      ...{
                        dots: true,
                        infinite: res.charging_record_images?.length > 3,
                        speed: 500,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        lazyLoad: 'anticipated',
                      }
                      }
                    >
                      {
                        res.charging_record_images?.map((imageURI: string) => {
                          const isPdf = imageURI.endsWith('.pdf');

                          return (
                            <div key={imageURI}>
                              {
                                isPdf
                                  ? (
                                    <div
                                      onDoubleClick={() => {
                                        window.open(imageURI, '_blank');
                                      }}
                                    >
                                      <Document file={imageURI}>
                                        <Page
                                          pageNumber={1}
                                          width={100}
                                          scale={1.5}
                                          renderAnnotationLayer={false}
                                          renderTextLayer={false}
                                        />
                                      </Document>
                                    </div>
                                  ) : (
                                    <Zoom>
                                      <img
                                        src={imageURI}
                                        alt={imageURI}
                                        style={{
                                          width: '100px',
                                          maxHeight: '300px',
                                          margin: '0 auto',
                                        }}
                                      />
                                    </Zoom>
                                  )
                              }
                            </div>
                          );
                        })
                      }
                    </Slider>
                  </div>
                )
              }

              <div>
                <a
                  href="https://www.notion.so/eonrides/Troubleshooting-65effe444dc545d49c5a6bde69fbe050"
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontSize: '14px', textAlign: 'center' }}
                >
                  Missing items? Follow these steps...
                </a>
              </div>
            </div>
          </Section>
        </div>

        <div className="mt-4">
          <Section title="Incidentals">
            <div className="mt-4 d-flex flex-column gap-2 align-items-center">
              {
                (
                  (
                    moment().isAfter(moment(res.start_time))
                    && moment().isBefore(moment(res.end_time).add(60, 'days'))
                  )
                  || owner?.is_superadmin
                )
                && (
                  <div>
                    <Button
                      style={{ boxShadow: 'none' }}
                      onClick={() => setShowAddIncidentalModal(true)}
                    >
                      + Add Incidental
                    </Button>
                  </div>
                )
              }

              <div className="d-flex flex-row w-100">
                <table className="w-100">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Time of Offense</th>
                      <th>Status</th>
                      <th>Amount</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      res.incidentals?.map(incidental => (
                        <tr key={incidental.id}>
                          <td>{snakeToTitleCase(incidental.category)}</td>
                          <td>{moment(incidental.offense_date).format('MM/DD/YY [at] h:mma z')}</td>
                          <td>
                            {
                              incidental.is_paid
                                ? 'paid'
                                : incidental.status
                            }
                          </td>
                          <td>{`$${incidental.amount}`}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>

              <div>
                <Typography>
                  * Incidentals will be approved for validity.
                  Approved incidentals will usually be sent in a separate payout from your regular earnings
                </Typography>
                <Typography>
                  * Parking/speeding incidentals can be submitted for review up to 60 days after the trip ends,
                  however all other types of incidentals can only be submitted within 24 hours of the trip ending.
                </Typography>
              </div>
            </div>
          </Section>
        </div>

        <div className="d-flex justify-content-center mt-4 flex-column align-items-center gap-3">
          <Button
            type="submit"
            style={{
              width: '200px',
            }}
            onClick={formikForm.handleSubmit}
          >
            Update
          </Button>

          {
            res.is_block && (
              <Button
                type="submit"
                style={{
                  width: '200px',
                  backgroundColor: variables.red3,
                }}
                onClick={cancelBlockedTrip}
              >
                Cancel Trip
              </Button>
            )
          }
        </div>
      </div>

      <AddTolls
        visible={showAddTollsModal}
        reservation={res}
        onClose={() => setShowAddTollsModal(false)}
        onSuccess={(reservation: IReservation) => {
          setRes(reservation);
        }}
      />

      <AddChargingRecord
        visible={showChargingRecordsModal}
        reservation={res}
        onClose={() => setShowChargingRecordsModal(false)}
        onSuccess={(reservation: IReservation) => {
          setRes(reservation);
        }}
      />
    </div>
  );
};

export default EditReservation;
