import { Tooltip } from 'react-tooltip';
import moment from 'moment';

import { Card, Typography } from 'components/common';
import { IReservation } from 'types';
import { Icon } from '@mui/material';

interface IProps {
  res: IReservation;
}

const TripSummary = ({
  res,
}: IProps) => {
  const getOwnerDeliveryPayout = (res: IReservation) => {
    let deliveryPayout = 'N/A';

    if (!res.car.is_fully_managed && res.custom_pickup_location !== '') {
      deliveryPayout = `${(0.85 * res.total_delivery_cost).toFixed(2)}`;
    }

    return deliveryPayout;
  };

  return (
    <Card
      type="button"
      className="d-flex flex-column gap-3 cursor-default focus-disable active-disable"
    >
      <div className="w-100">
        {!res.custom_pickup_location && (
          <Typography variant="body2" className="m-0 d-flex gap-3 align-items-start justify-content-between">
            <span>Where</span>
            <span className="text-right">
              {res.pickup_location.address}
            </span>
          </Typography>
        )}

        {res.custom_pickup_location && res.custom_return_location && (
          <>
            <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between">
              <span>Delivery address</span>
              <span className="text-right">{res.custom_pickup_location}</span>
            </Typography>

            <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between">
              <span>Return address</span>
              <span className="text-right">{res.custom_return_location}</span>
            </Typography>
          </>
        )}

        <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between">
          <span>Start Time</span>
          <span className="text-right">
            {
              moment.tz(
                res.start_time,
                res.pickup_location.timezone,
              ).format('MMM DD - h:mma z')
            }
          </span>
        </Typography>

        <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between">
          <span>End Time</span>
          <span className="text-right">
            {
              moment.tz(
                res.end_time,
                res.return_location.timezone,
              ).format('MMM DD - h:mma z')
            }
          </span>
        </Typography>

        <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3">
          <span>Total Trip Days</span>
          <span className="text-right">
            {res.num_days}
          </span>
        </Typography>

        <div className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3">
          <Typography variant="body2" className="m-0">Price</Typography>
          <div>
            {
              res.reservation_rates?.map(rate => (
                <Typography variant="body2" className="m-0" key={rate.id}>
                  {`$${rate.daily_rate.toFixed(2)} x ${rate.num_days} days`}
                </Typography>
              ))
            }
          </div>
        </div>

        {
          res.weekly_discount > 0 && (
            <div className="d-flex flex-row w-100 align-items-center justify-content-space-between mt-3">
              <div className="d-flex flex-row align-items-center gap-1">
                <Typography
                  variant="body2"
                  className="m-0"
                >
                  Weekly Discount (7+ days)
                </Typography>

                <Icon
                  data-tooltip-id="weekly-discount"
                  data-tooltip-html={`
                  Customers automatically get a discount for booking longer.<br/>
                  See the FAQ for more details.
                `}
                >
                  info
                </Icon>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <Typography
                  variant="body2"
                  className="m-0 text-right text-danger"
                >
                  {`-$${res.weekly_discount.toFixed(2)}`}
                </Typography>
              </div>

              <Tooltip
                id="weekly-discount"
              />
            </div>
          )
        }

        {
          res.monthly_discount > 0 && (
            <div className="d-flex flex-row w-100 align-items-center justify-content-space-between mt-3">
              <div className="d-flex flex-row align-items-center gap-1">
                <Typography
                  variant="body2"
                  className="m-0"
                >
                  Monthly Discount (7+ days)
                </Typography>

                <Icon
                  data-tooltip-id="monthly-discount"
                  data-tooltip-html={`
                  Customers automatically get a discount for booking longer.<br/>
                  See the FAQ for more details.
                `}
                >
                  info
                </Icon>
              </div>

              <div className="d-flex align-items-center justify-content-center">
                <Typography
                  variant="body2"
                  className="m-0 text-right text-danger"
                >
                  {`-$${res.monthly_discount.toFixed(2)}`}
                </Typography>
              </div>

              <Tooltip
                id="monthly-discount"
              />
            </div>
          )
        }

        <Typography
          variant="body2"
          className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3"
        >
          <span>You Keep</span>
          <span className="text-right">
            {`${(100 - (res.management_commission_percentage * 100)).toFixed(2)}%`}
          </span>
        </Typography>

        <hr style={{ border: 'none', borderTop: '2px solid #000', marginTop: '20px' }} />

        <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3">
          <span>Extra Miles</span>
          <span className="text-right">
            {`$${(res.extra_miles * 0.7).toFixed(2)}`}
          </span>
        </Typography>

        <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3">
          <span>Charging</span>
          <span className="text-right">
            {`$${res.charging_used.toFixed(2)}`}
          </span>
        </Typography>

        <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3">
          <span>Tolls</span>
          <span className="text-right">
            {`$${res.tolls_used.toFixed(2)}`}
          </span>
        </Typography>

        {
          getOwnerDeliveryPayout(res) !== 'N/A' && (
            <Typography variant="body2" className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3">
              <span>Delivery</span>
              <span className="text-right">
                {`$${getOwnerDeliveryPayout(res)}`}
              </span>
            </Typography>
          )
        }

        {
          res.owner_discount > 0 && (
            <Typography
              variant="body2"
              className="m-0 d-flex gap-3 align-items-center justify-content-between mt-3"
            >
              <span>Owner Discount</span>
              <span className="text-right text-danger">
                {`-$${res.owner_discount.toFixed(2)}`}
              </span>
            </Typography>
          )
        }

        <Typography
          variant="body2"
          className="m-0 d-flex gap-3 align-items-center justify-content-between mt-4"
          style={{ fontWeight: 600 }}
        >
          <span>Your Earnings</span>
          <span className="text-right">
            {`$${res.owner_earnings.toFixed(2)}`}
          </span>
        </Typography>
      </div>
    </Card>
  );
};

export default TripSummary;
